<script setup lang="ts">
const props = defineProps<{
	id: string;
}>();
</script>

<template>
    <div class="orderBooks">
        <OrderBook :id="props.id" :outcome="true"></OrderBook>
        <OrderBook :id="props.id" :outcome="false"></OrderBook>
    </div>
</template>

<style lang="scss" scoped>
.orderBooks {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
}

@media (max-width: 768px) {
    .orderBooks {
        flex-direction: column;
    }
}
</style>
