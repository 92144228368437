<script setup lang="ts">
import {
	PhBellSimple,
	PhCompass,
	PhHouse,
	PhPlus,
	PhPlusCircle,
	PhUsers,
} from "@phosphor-icons/vue";
import { useAuthenticationStore } from "~/stores/authentication";
import { useViewportStore } from "~/stores/viewport";

const authenticationStore = useAuthenticationStore();
const viewport = useViewportStore();

type NavigationItem = {
	icon: any;
	text: string;
	to: string;
	needsAuth?: boolean;
	hide?: boolean;
	comingSoon?: boolean;
	id?: string;
};

const navigationItems: NavigationItem[] = [
	{ icon: PhHouse, text: "home", to: "/" },
	{ icon: PhCompass, text: "explore", to: "/explore" },
	{
		icon: PhPlusCircle,
		text: "Create Market",
		to: "/create",
		needsAuth: true,
		hide: viewport.isDesktop.value,
		id: "create-mobile",
	},
	{
		icon: PhBellSimple,
		text: "notifications",
		to: "/notifications",
		needsAuth: true,
		comingSoon: true,
	},
	{
		icon: PhUsers,
		text: "friends",
		to: "/friends",
		needsAuth: true,
		comingSoon: true,
	},
	{
		icon: PhPlus,
		text: "create",
		to: "/create",
		needsAuth: true,
		hide: !viewport.isDesktop.value,
		id: "create",
	},
];
</script>

<template>
    <nav id="navigation">
        <ul>
            <template v-for="item in navigationItems" :key="item.text">
                <li v-if="!item.needsAuth || (authenticationStore.isAuthenticated() && !item.hide)">
                    <div v-if="item.comingSoon" class="coming-soon" :id="item.id" v-tooltip="$t('comingSoon')">
                        <component :is="item.icon" weight="regular" />
                        <span>{{ $t(item.text) }}</span>
                    </div>
                    <router-link v-else :id="item.id" :to="item.to" v-slot="{ isActive }" v-vibrate="1">
                        <component :is="item.icon" :weight="isActive && !(item.id == 'create') ? 'fill' : 'regular'" />
                        <span>{{ $t(item.text) }}</span>
                    </router-link>
                </li>
            </template>
        </ul>
    </nav>
</template>

<style lang="scss" scoped>
nav#navigation ul {
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;

    li {
        a#create {
            border: 2px solid var(--mint-400);
            border-radius: 3em;
            margin: 2em -2px;
            transition: background-color 0.2s ease-in-out;

            &.router-link-active {
                background-color: var(--mint-400);
                color: var(--mint-950);
            }
        }
    }

    li :is(a, .coming-soon) {
        text-decoration: none;
        color: var(--mint-400);
        max-width: 200px;

        display: flex;
        align-items: center;
        gap: 1em;

        padding: 0.8em;
        border-radius: 2em;

        transition: background 0.1s ease-in-out;

        &:is(.coming-soon) {
            cursor: not-allowed;
            opacity: 0.4;
        }

        &:hover {
            background-color: var(--mint-950);
        }

        svg {
            width: 1.7em;
            height: 1.7em;
        }

        span {
            font-size: 1.2em;
        }

        &.router-link-active {
            color: var(--mint-300);
        }
    }

    @media (max-width: 1050px) {
        li a#create {
            max-width: fit-content;
        }
        li :is(a, .coming-soon) span {
            display: none;
        }
    }

    @media (max-width: 600px) {
        flex-direction: row;
        justify-content: space-around;
    }
}
</style>
