<template>
    <Skeleton height="18rem" width="52rem" borderRadius="20px" class="skeleton-market"></Skeleton>
    <Skeleton height="18rem" width="52rem" borderRadius="20px" class="skeleton-market"></Skeleton>
    <Skeleton height="18rem" width="52rem" borderRadius="20px" class="skeleton-market"></Skeleton>
    <Skeleton height="18rem" width="52rem" borderRadius="20px" class="skeleton-market"></Skeleton>
    <Skeleton height="18rem" width="52rem" borderRadius="20px" class="skeleton-market"></Skeleton>
    <Skeleton height="18rem" width="52rem" borderRadius="20px" class="skeleton-market"></Skeleton>
</template>

<style lang="scss" scoped>
.skeleton-market {
    margin-bottom: 1em;
    box-sizing: content-box;
    min-width: 22rem;
    max-width: 50rem;
}

@media (max-width: 600px) {
    .skeleton-market {
        padding: 1.25rem;
        min-width: unset;
        max-width: 85vw;
        width: unset;
    }
}
</style>
