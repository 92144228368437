import type { ElysiaApp } from "@/index";
import { treaty } from "@elysiajs/eden";
import { useAuthenticationStore } from "~/stores/authentication";

export const api = treaty<ElysiaApp>(import.meta.env.VITE_BACKEND_URL, {
	onRequest: [
		async () => {
			try {
				const authStore = useAuthenticationStore();
				const accessToken = await authStore.getAccessToken();
				if (accessToken) {
					return {
						headers: {
							authorization: `Bearer ${accessToken}`,
						},
					};
				}
			} catch (e) {}
		},
	],
});
