<script setup lang="ts">
import { PhMagnifyingGlass } from "@phosphor-icons/vue";
import { timestamp, useLastChanged } from "@vueuse/core";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import Message from "primevue/message";
import { onMounted, ref, useTemplateRef, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { api } from "~/inertia/api";

const router = useRouter();
const i18n = useI18n();

const query = ref<string>("");
const lastChanged = useLastChanged(query, {
	initialValue: timestamp() - 1000 * 60 * 5,
});
const select = useTemplateRef("select");
const target = ref<{
	name: string;
	value: "market" | "user";
}>({
	name: i18n.t("search.predictions"),
	value: "market",
});

const marketSearchResults = ref<string[]>();
const userSearchResults = ref<string[]>();

const options = ref([
	{
		name: i18n.t("search.predictions"),
		value: "market",
	},
	{
		name: i18n.t("search.users"),
		value: "user",
	},
]);

async function getSearchResults() {
	if (!query.value || query.value.length < 3) {
		marketSearchResults.value = [];
		userSearchResults.value = [];
		return;
	}
	const endpoint = target.value.value;
	const searchResults = await api.search[endpoint]({
		query: query.value,
	}).get();
	if (searchResults.error) {
		return;
	}
	endpoint === "market"
		? (marketSearchResults.value = searchResults.data)
		: (userSearchResults.value = searchResults.data);
}

watch(query, () => {
	if (lastChanged.value > 100) {
		getSearchResults();
	}
});

onMounted(() => {
	const query = router.currentRoute.value.params.query;
	if (query) {
		router.push(`/search/market/${query}`);
	}
	select.value?.$emit;
});
</script>

<template>
    <main>
        <h1>{{ $t("search.search") }}</h1>

        <IconField>
            <InputIcon>
                <PhMagnifyingGlass weight="bold" />
            </InputIcon>
            <InputText
                type="text"
                v-model="query"
                :placeholder="$t('search.search')"
                :maxLength="100"
                style="border-radius: 99px"
                fluid
            />
        </IconField>

        <SelectButton
            ref="select"
            style="margin: 1rem 0"
            v-model="target"
            :options="options"
            optionLabel="name"
            @update:modelValue="getSearchResults"
            fluid
        />
        <div v-if="target.value === 'market'">
            <Market v-for="marketId in marketSearchResults" :id="marketId" />
        </div>
        <div v-else-if="target.value === 'user'">
            <UserDetails v-for="userId in userSearchResults" :id="userId" />
        </div>
    </main>
</template>
