<script setup lang="ts">
import { onMounted, ref, useTemplateRef, watch } from "vue";

const props = defineProps<{
	percentageOne: number;
	percentageTwo?: number;
	labelOne: string;
	labelTwo: string;
}>();

const buttonOneWidth = ref<string>("");
const buttonTwoWidth = ref<string>("");
const percentageTwo = ref<number>(0);

if (!props.percentageTwo) {
	percentageTwo.value = 100 - props.percentageOne;
} else {
	percentageTwo.value = props.percentageTwo;
}

function updateColors() {
	buttonOneWidth.value = `calc(${props.percentageOne * 0.01} * 20em)`;
	buttonTwoWidth.value = `calc(${percentageTwo.value * 0.01} * 20em)`;
}

onMounted(() => {
	updateColors();
});

watch(props, () => {
	updateColors();
});
</script>

<template>
    <div class="answers">
        <button ref="marketButtonOne" class="marketOption" type="button">{{ labelOne }} - {{ percentageOne }}%</button>
        <button ref="marketButtonTwo" class="marketOption" type="button">{{ labelTwo }} - {{ percentageTwo }}%</button>
    </div>
</template>

<style stylee="scss" scoped>
.answers {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.marketOption {
    padding: 1em;
    margin-top: 0.5em;
    text-align: left;
    min-width: fit-content;
    border: none;
    cursor: pointer;
    border-radius: 0px;
    transition: all 0.15s ease-in-out;

    &:first-of-type {
        color: var(--mint-100);
        background-color: var(--mint-900);
        border-radius: 10px 0px 0px 10px;
        width: v-bind(buttonOneWidth);
    }

    &:last-of-type {
        color: var(--cinnamon-100);
        background-color: var(--cinnamon-900);
        border-radius: 0px 10px 10px 0px;
        width: v-bind(buttonTwoWidth);
    }

    background-color: var(--darkGray-500);
    font-family: var(--fonts);

    &:hover {
        background-color: var(--darkGray-400);

        &:first-of-type {
            background-color: var(--mint-800);
        }

        &:last-of-type {
            background-color: var(--cinnamon-800);
        }
    }
}
</style>
