import "./styles/main.scss";
import "primeicons/primeicons.css";

import messages from "@intlify/unplugin-vue-i18n/messages";
import { VueUmamiPlugin } from "@jaseeey/vue-umami-plugin";
import Aura from "@primevue/themes/aura";
import { createPinia } from "pinia";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import PrimeVue from "primevue/config";
import DialogService from "primevue/dialogservice";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import { createApp } from "vue";
import Vue3linkify from "vue-3-linkify";
import { createI18n } from "vue-i18n";
import Auth from "~/directives/auth";
import Ripple from "~/directives/ripple";
import Vibrate from "~/directives/vibrate";
import App from "./App.vue";
import router from "./router";

const pinia = createPinia().use(piniaPluginPersistedState);

createApp(App)
	.use(pinia)
	.use(
		createI18n({
			locale: "en-US",
			messages,
		}),
	)
	.use(router)
	.use(PrimeVue, {
		theme: {
			preset: Aura,
			options: {
				prefix: "p",
				darkModeSelector: ".p-dark",
				cssLayer: false,
			},
		},
	})
	.use(
		VueUmamiPlugin({
			websiteID: import.meta.env.VITE_UMAMI_WEBSITE_ID,
			scriptSrc: "https://us.umami.is/script.js",
			router: router,
		}),
	)
	.use(DialogService)
	.use(ToastService)
	.use(Vue3linkify)
	.directive("auth", Auth)
	.directive("ripple", Ripple)
	.directive("tooltip", Tooltip)
	.directive("vibrate", Vibrate)
	.mount("#app");
