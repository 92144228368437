<script setup lang="ts">
import SelfProfile from "~/components/SelfProfile.vue";
import { useViewportStore } from "~/stores/viewport";
import Logo from "./Logo.vue";
import Navigation from "./Navigation.vue";

const viewport = useViewportStore();
</script>

<template>
    <header>
        <Logo />
        <Navigation v-if="viewport.isDesktop.value" />
        <section class="profile">
            <SelfProfile />
        </section>
    </header>
</template>

<style lang="scss" scoped>
header {
    display: flex;
    flex-direction: column;
    gap: clamp(3em, 10vh, 5em);

    padding: 2em;
    position: sticky;
    height: 100vh;
    top: 0;

    .profile {
        flex: 1;
        display: flex;
        align-items: end;
        justify-content: end;
    }
}

@media (max-width: 1050px) {
    header {
        align-items: center;
        gap: 2em;
        padding: 1rem 0;

        .profile {
            flex-direction: column-reverse !important;
            margin-bottom: 1rem;
        }
    }
}

@media (max-width: 600px) {
    header {
        position: initial;
        height: auto;
        flex-direction: row;
        margin: unset;

        .profile {
            margin-bottom: unset;
        }
    }
}
</style>
