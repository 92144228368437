import { trackUmamiEvent } from "@jaseeey/vue-umami-plugin";
import { defineStore } from "pinia";
import { ref } from "vue";
import { type OidcProvider, getFlow } from "~/authentication/oidc";

function generateRandomString(length: number) {
	const array = new Uint8Array(length);
	window.crypto.getRandomValues(array);
	return Array.from(array, (byte) => byte.toString(16).padStart(2, "0")).join(
		"",
	);
}

export const useOidcStore = defineStore(
	"oidc",
	() => {
		const csrfToken = ref<string | undefined>(undefined);
		const pkceVerifier = ref<string | undefined>(undefined);
		const activeOidcProvider = ref<OidcProvider | undefined>(undefined);
		const id_token = ref<string | undefined>(undefined);

		async function startFlow(provider: OidcProvider) {
			trackUmamiEvent("sign-in", { provider: provider });
			activeOidcProvider.value = provider;
			csrfToken.value = generateRandomString(32);
			pkceVerifier.value = generateRandomString(32);
			await getFlow(provider).start(csrfToken.value, pkceVerifier.value);
		}

		async function handleCallback(redirect_url: string): Promise<void> {
			if (!activeOidcProvider.value) {
				throw new Error("No active OIDC provider");
			}
			const flow = getFlow(activeOidcProvider.value);
			try {
				updateIdToken(
					await flow.callback(
						redirect_url,
						csrfToken.value,
						pkceVerifier.value,
					),
				);
			} catch (e) {
				reset();
				console.error("Error handling callback:", e);
			}
		}

		function updateIdToken(new_id_token: string) {
			reset();
			id_token.value = new_id_token;
		}

		function reset() {
			csrfToken.value = undefined;
			pkceVerifier.value = undefined;
			activeOidcProvider.value = undefined;
			id_token.value = undefined;
		}

		return {
			csrfToken,
			pkceVerifier,
			activeOidcProvider,
			id_token,
			startFlow,
			handleCallback,
			reset,
		};
	},
	{
		persist: true,
	},
);
