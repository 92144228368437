import Ripple from "~/components/vfx/Ripple.vue";
import { showFx } from "~/utils/popup";

export default {
	mounted(el: any, binding: any, _vn: any) {
		if (binding.value === false) return;

		el.addEventListener("click", () => {
			const rect = el.getBoundingClientRect();
			const x = rect.left + el.offsetWidth / 2;
			const y = rect.top + el.offsetHeight / 2;

			const { dispose } = showFx(
				Ripple,
				{ x, y },
				{
					// @ts-ignore Won't be type-safe without insane TypScript fuckery: https://github.com/misskey-dev/misskey/blob/develop/packages/frontend/src/os.ts#L153-L173
					end: () => dispose(),
				},
			);
		});
	},
};
