import { useDialog } from "primevue/usedialog";
import type { Directive } from "vue";
import { useI18n } from "vue-i18n";
import { useAuthenticationStore } from "~/stores/authentication";
import SignIn from "~/views/authentication/SignIn.vue";

let dialogInstance: ReturnType<typeof useDialog>;
let i18nInstance: ReturnType<typeof useI18n>;

export function initializeDirective() {
	dialogInstance = useDialog();
	// @ts-ignore
	i18nInstance = useI18n();
}

export default {
	mounted(el) {
		const authenticationStore = useAuthenticationStore();

		function openSignin() {
			if (!dialogInstance) {
				console.error("Dialog instance not initialized!");
				return;
			}

			dialogInstance.open(SignIn, {
				props: {
					header: i18nInstance.t("loginSignup"),
					modal: true,
				},
			});
		}

		el.addEventListener("mousedown", (event: Event) => {
			if (!authenticationStore.isAuthenticated()) {
				event.preventDefault();
				openSignin();
			}
		});
	},
} as Directive;
