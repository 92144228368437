<!-- https://tradingview.github.io/lightweight-charts/tutorials/vuejs/wrapper#composition-api -->
<script setup lang="ts">
import type { MarketGraphData } from "@/database/entities/market";
import { type IChartApi, createChart } from "lightweight-charts";
import { onMounted, onUnmounted, useTemplateRef, watch } from "vue";
import { ChartIntervals } from "~/utils/types";

const props = defineProps({
	yesData: {
		type: Array,
		required: true,
	},
	noData: {
		type: Array,
		required: true,
	},
	autosize: {
		default: true,
		type: Boolean,
	},
	chartOptions: {
		type: Object,
	},
	yesSeriesOptions: {
		type: Object,
	},
	noSeriesOptions: {
		type: Object,
	},
	timeScaleOptions: {
		type: Object,
	},
	priceScaleOptions: {
		type: Object,
	},
	priceScaleId: {
		type: String,
		default: "right",
	},
});

// Lightweight Charts™ instances are stored as normal JS variables
// If you need to use a ref then it is recommended that you use `shallowRef` instead
let yesSeries: any | null;
let noSeries: any | null;
let chart: IChartApi | null;
let toolTipElement: HTMLElement | null = null;

const chartContainer = useTemplateRef("chartContainer");

// Helper function to find nearest data points and interpolate
const findInterpolatedValue = (series: any, time: number, data: any[]) => {
	// Find the nearest points before and after the current time
	const beforePoint = [...data].reverse().find((point) => point.time <= time);
	const afterPoint = data.find((point) => point.time >= time);

	if (!beforePoint || !afterPoint) {
		return beforePoint?.value || afterPoint?.value || null;
	}

	// If exactly on a point, return that value
	if (beforePoint.time === time) return beforePoint.value;
	if (afterPoint.time === time) return afterPoint.value;

	// Interpolate between the two points
	const timeDiff = afterPoint.time - beforePoint.time;
	const valueDiff = afterPoint.value - beforePoint.value;
	const ratio = (time - beforePoint.time) / timeDiff;

	return beforePoint.value + valueDiff * ratio;
};

const fitContent = () => {
	if (!chart) return;
	chart.timeScale().fitContent();
};

const resize = (width: number, height: number) => {
	if (!chart) return;
	chart.resize(width, height);
};

const getChart = () => {
	return chart;
};

const setChartInterval = (interval: ChartIntervals) => {
	if (!chart) return;
	let filteredYesData = props.yesData as MarketGraphData[];
	let filteredNoData = props.noData as MarketGraphData[];
	if (interval === ChartIntervals.Hour) {
		filteredYesData = (props.yesData as MarketGraphData[]).filter(
			(data) => data.time > Date.now() / 1000 - 3600,
		);
		filteredNoData = (props.noData as MarketGraphData[]).filter(
			(data) => data.time > Date.now() / 1000 - 3600,
		);
	}
	if (interval === ChartIntervals.Day) {
		filteredYesData = (props.yesData as MarketGraphData[]).filter(
			(data) => data.time > Date.now() / 1000 - 86400,
		);
		filteredNoData = (props.noData as MarketGraphData[]).filter(
			(data) => data.time > Date.now() / 1000 - 86400,
		);
	}
	if (interval === ChartIntervals.Week) {
		filteredYesData = (props.yesData as MarketGraphData[]).filter(
			(data) => data.time > Date.now() / 1000 - 604800,
		);
		filteredNoData = (props.noData as MarketGraphData[]).filter(
			(data) => data.time > Date.now() / 1000 - 604800,
		);
	}
	if (interval === ChartIntervals.Month) {
		filteredYesData = (props.yesData as MarketGraphData[]).filter(
			(data) => data.time > Date.now() / 1000 - 2592000,
		);
		filteredNoData = (props.noData as MarketGraphData[]).filter(
			(data) => data.time > Date.now() / 1000 - 2592000,
		);
	}
	yesSeries.setData(filteredYesData);
	noSeries.setData(filteredNoData);
	chart.timeScale().fitContent();
};

defineExpose({ fitContent, resize, getChart, setChartInterval });

const resizeHandler = () => {
	if (!chart || !chartContainer.value) return;
	const dimensions = chartContainer.value.getBoundingClientRect();
	chart.resize(Math.min(dimensions.width, 640), dimensions.height);
};

const createTooltip = () => {
	if (!chartContainer.value) return;

	toolTipElement = document.createElement("div");
	toolTipElement.className = "tooltip-wrapper";
	toolTipElement.style.position = "absolute";
	toolTipElement.style.display = "none";
	toolTipElement.style.padding = "8px";
	toolTipElement.style.backgroundColor = "var(--acrylicPanel)";
	toolTipElement.style.backdropFilter = "blur(10px)";
	toolTipElement.style.color = "var(--fg)";
	toolTipElement.style.borderRadius = "4px";
	toolTipElement.style.fontSize = "12px";
	toolTipElement.style.zIndex = "1000";
	toolTipElement.style.pointerEvents = "none";

	chartContainer.value.appendChild(toolTipElement);
};

const addSeriesAndData = () => {
	yesSeries = chart?.addLineSeries({
		...props.yesSeriesOptions,
		lastValueVisible: false,
		priceLineVisible: false,
	});
	yesSeries?.setData(props.yesData);

	noSeries = chart?.addLineSeries({
		...props.noSeriesOptions,
		lastValueVisible: false,
		priceLineVisible: false,
	});
	noSeries?.setData(props.noData);
};

onMounted(() => {
	if (!chartContainer.value) return;

	chart = createChart(chartContainer.value, props.chartOptions);

	createTooltip();
	addSeriesAndData();

	if (props.priceScaleOptions) {
		chart
			.priceScale(props.priceScaleId)
			.applyOptions(props.priceScaleOptions);
	}

	if (props.timeScaleOptions) {
		chart.timeScale().applyOptions(props.timeScaleOptions);
	}

	chart.subscribeCrosshairMove((param) => {
		if (
			!toolTipElement ||
			!chartContainer.value ||
			param.time === undefined
		) {
			if (toolTipElement) toolTipElement.style.display = "none";
			return;
		}

		// Get the current time from the crosshair
		const currentTime = param.time;

		// Find interpolated values for both series
		const yesValue = findInterpolatedValue(
			yesSeries,
			new Date(currentTime).getTime(),
			props.yesData,
		);
		const noValue = findInterpolatedValue(
			noSeries,
			new Date(currentTime).getTime(),
			props.noData,
		);

		if (yesValue === null || noValue === null) {
			toolTipElement.style.display = "none";
			return;
		}

		// Format the tooltip content
		toolTipElement.innerHTML = `
			<div style="display: flex; flex-direction: column; gap: 4px;">
				<div style="color: ${props.yesSeriesOptions?.color || "#00F29C"}">
					Yes: ${yesValue.toFixed(0)}%
				</div>
				<div style="color: ${props.noSeriesOptions?.color || "#D8605A"}">
					No: ${noValue.toFixed(0)}%
				</div>
			</div>
		`;

		// Position the tooltip
		const containerRect = chartContainer.value.getBoundingClientRect();
		const tooltipRect = toolTipElement.getBoundingClientRect();
		const left = (param.point?.x ?? 0) + 18;
		const top = (param.point?.y ?? 0) - tooltipRect.height / 2;

		toolTipElement.style.left = `${Math.min(left, containerRect.width - tooltipRect.width - 10)}px`;
		toolTipElement.style.top = `${Math.max(10, Math.min(top, containerRect.height - tooltipRect.height - 10))}px`;
		toolTipElement.style.display = "block";
	});

	chart.timeScale().fitContent();

	if (props.autosize) {
		window.addEventListener("resize", resizeHandler);
	}

	const logo = document.getElementById("tv-attr-logo");
	if (logo) {
		logo.setHTMLUnsafe(
			`
			<div style="transform: translateY(-20px); filter: grayscale(1); opacity: 0.3">
				<div style="display: flex; flex-direction: row; align-items: center; gap: 0.5em; padding: 0.5em">
					<img class="logo" src="/watermark.svg" height="24px"></img>
					<img src="/wordmark.svg" height="24px" style="filter: contrast(0.2) brightness(1.5)"></img>
				</div>
				<p style="width: max-content; margin-left: 0.5em; font-weight: bold; font-size: 0.9em;">Source: https://inertia.social</p>
			</div>
			`,
		);
		logo.setAttribute("href", "");
	}
});

onUnmounted(() => {
	if (chart) {
		chart.remove();
		chart = null;
	}
	if (yesSeries || noSeries) {
		yesSeries = null;
		noSeries = null;
	}
	if (toolTipElement?.parentNode) {
		toolTipElement.parentNode.removeChild(toolTipElement);
		toolTipElement = null;
	}
	window.removeEventListener("resize", resizeHandler);
});

// Watchers remain the same
watch(
	() => props.autosize,
	(enabled) => {
		if (!enabled) {
			window.removeEventListener("resize", resizeHandler);
			return;
		}
		window.addEventListener("resize", resizeHandler);
	},
);

watch(
	() => props.yesData,
	(newData) => {
		if (!yesSeries) return;
		yesSeries.setData(newData);
	},
);

watch(
	() => props.noData,
	(newData) => {
		if (!noSeries) return;
		noSeries.setData(newData);
	},
);

watch(
	() => props.chartOptions,
	(newOptions) => {
		if (!chart || !newOptions) return;
		chart.applyOptions(newOptions);
	},
);

watch(
	() => props.priceScaleOptions,
	(newOptions) => {
		if (!chart || !newOptions) return;
		chart.priceScale(props.priceScaleId).applyOptions(newOptions);
	},
);

watch(
	() => props.timeScaleOptions,
	(newOptions) => {
		if (!chart || !newOptions) return;
		chart.timeScale().applyOptions(newOptions);
	},
);
</script>

<template>
    <div style="height: 100%; position: relative" ref="chartContainer"></div>
</template>
