import { api } from "~/inertia/api";
import type { Comment, RawComment } from "~/utils/types";

export async function normalizeComment(comment: RawComment) {
	const normalized: Comment = {
		id: comment.id,
		content: comment.content,
		created_at: comment.created_at,
		updated_at: comment.updated_at,
		liked: false,
		likes: comment.likes,
		replies: 0,
	};
	if (comment.comments) {
		normalized.comments = [];
		for (const reply of comment.comments) {
			const normalizedReply = await normalizeComment(reply);
			normalized.comments.push(normalizedReply);
		}
	}
	const userData = await api.user({ id: comment.author.id.toString() }).get();
	if (userData.error) {
		console.error(userData.error);
		return normalized;
	}
	// @ts-ignore
	normalized.author = userData.data;
	return normalized;
}
