<script setup lang="ts">
import { PhXLogo } from "@phosphor-icons/vue";
import { OidcProvider } from "~/authentication/oidc";
import { useOidcStore } from "~/stores/oidc";

const oidcStore = useOidcStore();
</script>

<template>
    <section class="social-logins">
        <Button @click="oidcStore.startFlow(OidcProvider.Twitter)" label="Twitter" v-vibrate="1" class="twitter">
            <PhXLogo size="2em" />
        </Button>
        <Button @click="oidcStore.startFlow(OidcProvider.Discord)" label="Discord" v-vibrate="1" class="discord">
            <svg
                fill="transparent"
                width="80"
                height="40"
                viewBox="0 0 48 48"
                style="transform: scale(1.35)"
                class="discord-logo-container"
            >
                <rect width="100%" height="100%" fill="currentfill"></rect>
                <defs>
                    <g>
                        <defs>
                            <path
                                id="discord-def-face"
                                fill="currentcolor"
                                d="M40,12C40,12,35.415,8.412,30,8L29.512,8.976C34.408,10.174,36.654,11.891,39,14C34.955,11.935,30.961,10,24,10S13.045,11.935,9,14C11.346,11.891,14.018,9.985,18.488,8.976L18,8C12.319,8.537,8,12,8,12S2.879,19.425,2,34C7.162,39.953,15,40,15,40L16.639,37.815C13.857,36.848,10.715,35.121,8,32C11.238,34.45,16.125,37,24,37S36.762,34.45,40,32C37.285,35.121,34.143,36.848,31.361,37.815L33,40C33,40,40.838,39.953,46,34C45.121,19.425,40,12,40,12Z"
                            ></path>
                            <g id="discord-def-face-eyes">
                                <path
                                    id="discord-def-face-left-eye"
                                    fill="currentfill"
                                    d="M17.5,30C15.567,30,14,28.209,14,26C14,23.791,15.567,22,17.5,22S21,23.791,21,26C21,28.209,19.433,30,17.5,30Z"
                                ></path>
                                <path
                                    id="discord-def-face-right-eye"
                                    fill="currentfill"
                                    d="M30.5,30C28.567,30,27,28.209,27,26C27,23.791,28.567,22,30.5,22S34,23.791,34,26C34,28.209,32.433,30,30.5,30Z"
                                ></path>
                            </g>
                        </defs>
                        <g id="discordFaceID40">
                            <use href="#discord-def-face"></use>
                            <g id="discord-logo-eyes">
                                <mask id="mask-right-eye-wink">
                                    <ellipse fill="#FFFFFF" ry="15" rx="15" cy="39.7" cx="35"></ellipse>
                                    <ellipse fill="#000000" ry="15" rx="15" cy="40.5" cx="34"></ellipse>
                                </mask>
                                <mask id="mask-eyes-angry">
                                    <rect height="48" width="48" y="0" x="0" fill="#FFFFFF"></rect>
                                    <rect
                                        transform="rotate(45 24,14.5)"
                                        height="24"
                                        width="24"
                                        y="2.5"
                                        x="12"
                                        fill="#000000"
                                    ></rect>
                                </mask>
                                <g class="discord-eyes"><use xlink:href="#discord-def-face-eyes"></use></g>
                            </g>
                        </g>
                        <mask id="mask-outer-layer">
                            <rect width="100%" height="100%" fill="#FFFFFF"></rect>
                            <circle r="42%" cx="50%" cy="50%" fill="#000000"></circle>
                        </mask>
                        <mask id="mask-middle-layer">
                            <rect width="100%" height="100%" fill="#000000"></rect>
                            <circle r="43%" cx="50%" cy="50%" fill="#FFFFFF"></circle>
                            <circle r="32%" cx="50%" cy="50%" fill="#000000"></circle>
                        </mask>
                        <mask id="mask-inner-layer">
                            <rect width="100%" height="100%" fill="#000000"></rect>
                            <circle r="32%" cx="50%" cy="50%" fill="#FFFFFF"></circle>
                        </mask>
                    </g>
                </defs>
                <g class="discord-logo swirl-animation">
                    <use class="discord-original" xlink:href="#discordFaceID40"></use>
                    <use class="discord-inner-layer" xlink:href="#discordFaceID40" mask="url(#mask-inner-layer)"></use>
                    <use
                        class="discord-middle-layer"
                        xlink:href="#discordFaceID40"
                        mask="url(#mask-middle-layer)"
                    ></use>
                    <use class="discord-outer-layer" xlink:href="#discordFaceID40" mask="url(#mask-outer-layer)"></use>
                </g>
            </svg>
        </Button>
        <Button
            v-tooltip.top="$t('comingSoon')"
            disabled
            @click="oidcStore.startFlow(OidcProvider.Google)"
            label="Google"
            v-vibrate="1"
            class="google"
        >
            <div class="google-logo"></div>
        </Button>
        <!-- <Button @click="oidcStore.startFlow(OidcProvider.Bluesky)" label="Bluesky" v-vibrate="1" class="bluesky">
            <div class="bluesky-flutter">
                <svg id="flutterby" class="bluesky-flutter" viewBox="0 0 566 500" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <path
                            id="wing"
                            fill="currentColor"
                            d="M 123.244 35.008 C 188.248 83.809 283.836 176.879 283.836 235.857 C 283.836 316.899 283.879 235.845 283.836 376.038 C 283.889 375.995 282.67 376.544 280.212 383.758 C 266.806 423.111 214.487 576.685 94.841 453.913 C 31.843 389.269 61.013 324.625 175.682 305.108 C 110.08 316.274 36.332 297.827 16.093 225.504 C 10.271 204.699 0.343 76.56 0.343 59.246 C 0.343 -27.451 76.342 -0.206 123.244 35.008 Z"
                        />
                    </defs>
                    <use xlink:href="#wing" class="left" />
                    <use xlink:href="#wing" class="right" />
                </svg>
            </div>
        </Button> -->
    </section>
</template>

<style lang="scss" scoped>
.social-logins {
    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: space-between;

    button {
        flex: 1;
        min-width: 5rem;
        border: none !important;
    }
}

.discord {
    background-color: #5865f2;
    color: #fafafa;

    &:hover {
        background-color: #476ce5 !important;
        color: #fff !important;
    }
}

.twitter {
    background-color: #000;
    color: #fff;

    &:hover {
        background-color: #4ca1f4 !important;
        color: #000 !important;
    }
}

.google {
    background-color: #fff;

    &:hover {
        background-color: #202124 !important;
        .google-logo {
            width: 0;
            height: 0;
            border: 0.4em solid transparent;
            transform: scale(0.9) translateY(-1.5em);
            animation: googleHover 1s reverse ease-in infinite;

            &:before {
                width: 0;
                height: 0;
                border: 0em;
            }
        }
    }
}

/*
.bluesky {
    background-color: #1185fe;
    color: #fff;

    &:hover {
        background-color: #4e9ef4 !important;
        color: #fff !important;

        .bluesky-flutter .left {
            animation: flutter 430ms ease-in-out;
            --flip: 1;
        }
        .bluesky-flutter .right {
            animation: flutter 500ms ease-in-out;
            --flip: -1;
        }
        .bluesky-flutter svg {
            transform: rotate(-5deg);
            transition: 500ms;
        }

        @media (prefers-reduced-motion) {
            .bluesky-flutter .left,
            .bluesky-flutter .right {
                animation: none;
            }
        }
    }
}

.bluesky-flutter {
    display: inline-flex;
    gap: 0.5em;
    align-items: center;
}

.bluesky-flutter svg {
    width: 2em;
    height: 2em;
    transition: 200ms;
}

.bluesky-flutter .left {
    transform-origin: center;
}
.bluesky-flutter .right {
    transform-origin: center;
    transform: scale(-1, 1);
}

@keyframes flutter {
    10% {
        transform: scale(calc(var(--flip) * 1), 0.9);
    }
    20% {
        transform: scale(calc(var(--flip) * 0.5), 1);
    }
    40% {
        transform: scale(calc(var(--flip) * 0.9), 0.95);
    }
    60% {
        transform: scale(calc(var(--flip) * 0.3), 1);
    }
    80% {
        transform: scale(calc(var(--flip) * 0.9), 0.95);
    }
    100% {
        transform: scale(calc(var(--flip) * 1), 1);
    }
}
*/

.discord-logo {
    transform: scale(0.7);
    transform-origin: 24px 24px;
}
.discord-logo.swirl-animation .discord-outer-layer {
    transition: transform 600ms cubic-bezier(0.7, 1, 0.7, 1);
    transform-origin: 50% 50%;
}
.discord-logo-container:hover .swirl-animation .discord-outer-layer,
.animated .swirl-animation .discord-outer-layer {
    transform: scale(1.5) rotate(360deg);
}
.discord-logo.swirl-animation .discord-middle-layer {
    transition: transform 600ms cubic-bezier(0.5, 1, 0.5, 1);
    transform-origin: 50% 50%;
}
.discord-logo-container:hover .swirl-animation .discord-middle-layer,
.animated .swirl-animation .discord-middle-layer {
    transform: scale(1.4) rotate(360deg);
}
.discord-logo.swirl-animation .discord-inner-layer {
    fill: #5865f2;
    transition: transform 600ms cubic-bezier(0.3, 1, 0.3, 1);
    transform-origin: 50% 50%;
}
.discord-logo-container:hover .swirl-animation .discord-inner-layer,
.animated .swirl-animation .discord-inner-layer {
    fill: #476ce5;
    transform: scale(1.3) rotate(360deg);
}
.discord-logo.swirl-animation .discord-original {
    transition: visibility 0ms;
    transition-delay: 600ms;
}
.discord-logo-container:hover .swirl-animation .discord-original,
.animated .swirl-animation .discord-original {
    visibility: hidden;
    transition-delay: 0ms;
}

.google-logo {
    margin: 1em auto 0;
    width: 0.75em;
    height: 0.5em;
    border-top: 0.5em solid #4285f4;
    border-right: 0.5em solid #4285f4;
    border-bottom: 0.5em solid #4285f4;
    border-radius: 0 0 2.5em 0;
    position: relative;
    box-sizing: content-box;
    transform: translateX(0.5em) scale(0.9);
    transition: border-width 0.2s ease-in-out;

    &:before {
        content: "";
        display: block;
        width: 2.5em;
        height: 2.5em;
        border-top: 0.5em solid #ea4335;
        border-right: 0.5em solid transparent;
        border-bottom: 0.5em solid #34a853;
        border-left: 0.5em solid #fbbc05;
        border-radius: 2em;
        box-sizing: border-box;
        position: absolute;
        top: -1.5em;
        right: -0.5em;
        transition:
            border-color 0.2s ease-in-out,
            border-width 0.2s ease-in-out;
    }
}

@keyframes googleHover {
    0% {
        border-radius: 50%;
        box-shadow:
            2em 1.25em 0 #ea4335,
            0.7em 1em 0 #4285f4,
            -0.7em 1em 0 #fbbc05,
            -2em 1.25em 0 #34a853;
    }
    20% {
        border-radius: 50%;
        box-shadow:
            2em 0.5em 0 #ea4335,
            0.7em 1.25em 0 #4285f4,
            -0.7em 1em 0 #fbbc05,
            -2em 1em 0 #34a853;
    }
    40% {
        border-radius: 50%;
        box-shadow:
            2em 1em 0 #ea4335,
            0.7em 0.5em 0 #4285f4,
            -0.7em 1.25em 0 #fbbc05,
            -2em 1em 0 #34a853;
    }
    60% {
        border-radius: 50%;
        box-shadow:
            2em 1em 0 #ea4335,
            0.7em 1em 0 #4285f4,
            -0.7em 0.5em 0 #fbbc05,
            -2em 1.25em 0 #34a853;
    }
    80% {
        border-radius: 50%;
        box-shadow:
            2em 1em 0 #ea4335,
            0.7em 1em 0 #4285f4,
            -0.7em 1em 0 #fbbc05,
            -2em 0.5em 0 #34a853;
    }
    100% {
        border-radius: 50%;
        box-shadow:
            2em 1.25em 0 #ea4335,
            0.7em 1em 0 #4285f4,
            -0.7em 1em 0 #fbbc05,
            -2em 1.25em 0 #34a853;
    }
}
</style>
