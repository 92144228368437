<script setup lang="ts">
import type { MarketWithData } from "@/database/entities/market";
import type { User } from "@/database/entities/user";
import { PhChatCircleText, PhHeart, PhRepeat } from "@phosphor-icons/vue";
import DOMPurify from "dompurify";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { api } from "~/inertia/api";
import { useAuthenticationStore } from "~/stores/authentication";

const router = useRouter();
const authenticationStore = useAuthenticationStore();

const props = defineProps<{
	id: string;
}>();

const market = ref<MarketWithData>();
const author = ref<User>();
const likes = ref<number>(0);
const liked = ref<boolean>(false);
const empty = ref<boolean>(false);

async function performLike() {
	if (liked.value) {
		const result = await api
			.market({
				id: props.id.toString(),
			})
			.unlike.post();
		if (result.data?.ok) {
			liked.value = false;
			likes.value--;
		}
	} else {
		const result = await api
			.market({
				id: props.id.toString(),
			})
			.like.post();
		if (result.data?.ok) {
			liked.value = true;
			likes.value++;
		}
	}
}

function gotoComments() {
	router.push(`/p/${props.id}/?comments=0`);
}

onMounted(async () => {
	const marketData = await api.market({ id: props.id }).get();
	if (marketData.error) return;
	market.value = marketData.data;
	const userData = await api.user({ id: market.value.created_by }).get();
	if (userData.error) return;
	author.value = userData.data;
	likes.value = market.value.likes?.length ?? 0;
	if (authenticationStore.isAuthenticated()) {
		const likedResponse = await api.market({ id: props.id }).liked.get();
		if (likedResponse.status === 200) {
			liked.value = likedResponse.data?.likes ?? false;
		}
	}
	const latestTradeReq = await api
		.market({
			id: props.id,
		})
		.trades.get({
			query: {
				offset: 0,
				limit: 1,
			},
		});
	if (latestTradeReq.error) return;
	if (latestTradeReq.data?.length === 0) {
		empty.value = true;
	}
});
</script>

<template>
    <div
        class="container"
        :class="{ closed: market.closed }"
        v-if="market && author"
        @click="router.push(`/p/${id}`)"
        v-vibrate="1"
    >
        <div class="header">
            <UserDetails :id="market.created_by" />
            <div class="pricing"></div>
        </div>
        <div class="content">
            <h2>{{ market.question }}</h2>
            <p class="textBlock" v-html="DOMPurify.sanitize(market.description, { USE_PROFILES: { html: true } })"></p>

            <MarketButtonCombined
                v-if="market.outcome === undefined || market.outcome === null"
                :percentageOne="market.yesPercentage"
                :percentageTwo="market.noPercentage"
                :labelOne="$t('market.yes')"
                :labelTwo="$t('market.no')"
            />
            <OutcomeText v-else :outcome="market.outcome" />
            <img v-if="market.image" :src="market.image" @click.stop class="contentImage" />
        </div>
        <div class="interactions">
            <InteractButton
                :label="$t('interactions.like')"
                :state="liked"
                :count="likes"
                :icon="PhHeart"
                :action="performLike"
            />
            <InteractButton
                :label="$t('interactions.goToReplies')"
                :state="false"
                :count="market.comments?.length ?? 0"
                :ripple="false"
                :icon="PhChatCircleText"
                :action="gotoComments"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
h2 {
    font-weight: bold;
    margin: 0;
    font-size: 1.2rem;
}

.container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    background-color: var(--darkMint-950);
    background: linear-gradient(125deg, var(--darkMint-950), var(--darkMint-900));
    background-size: 400% 400%;
    padding: 1.5rem 2rem;
    gap: 1em;
    box-sizing: content-box;
    min-width: 20rem;
    max-width: 50rem;
    transition: background-position 0.15s ease;
    margin-bottom: 1em;

    &:hover {
        background-position: 40% 100%;
    }

    &.closed {
        filter: brightness(0.9);
        opacity: 0.8;
    }
}

@media (max-width: 600px) {
    .container {
        padding: 1.25rem;
        min-width: unset;
        width: unset;
    }
}

.question {
    display: flex;
    flex-direction: row;
    align-items: center;

    /*.categoryIcon {
        margin-right: 0.25em;
    }*/
}

.interaction {
    padding: 0.75em 1em;
    display: inline-flex;
    align-items: center;
    margin-right: 0.5em;
    font-size: 1em;
    color: var(--fg);
    border-radius: 10px;
    background-color: var(--darkGray-500);
    font-family: var(--fonts);
    border: none;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;

    svg {
        margin-right: 0.5em;
        font-size: 1em;
        color: var(--fg);
        border-radius: 10px;
        background-color: var(--darkGray-500);
        font-family: var(--fonts);
        align-items: center;
        border: none;
        cursor: pointer;
        transition: background-color 0.15s ease-in-out;

        svg {
            margin-right: 0.5em;
            transform: scale(1.2);
        }

        &:hover {
            background-color: var(--darkGray-400);
        }
    }

    .imageLink {
        padding: 0;
        display: inline-flex;
    }

    .header {
        display: flex;
        flex-direction: row;
    }
}
</style>
