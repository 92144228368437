<script setup lang="ts">
import { useInfiniteScroll } from "@vueuse/core";
import { ref, useTemplateRef } from "vue";
import { api } from "~/inertia/api";

const markets = ref<string[]>([]);
const empty = ref<boolean>(false);
const offset = ref(0);
const done = ref(false);
const timeline = useTemplateRef("timeline");

const { reset } = useInfiniteScroll(
	timeline,
	async () => {
		if (done.value) {
			return;
		}
		const timelineData = (
			await api
				.timeline({ filter: "all" })({ sort: "chronological" })({
					offset: offset.value,
				})
				.get()
		).data;
		if (!timelineData) {
			done.value = true;
			return;
		}
		markets.value.push(...Array.from(timelineData));
		offset.value += 10;
		if (timelineData.length !== 10) {
			done.value = true;
			return;
		}
	},
	{ distance: 10 },
);
</script>

<template>
    <main>
        <div class="blur top"></div>
        <div class="timeline-container">
            <div v-if="empty">
                <h1>{{ $t("empty") }}</h1>
            </div>
            <div v-else>
                <div class="timeline" ref="timeline">
                    <div v-for="item in markets" :key="item">
                        <Market :id="item.toString()" />
                    </div>
                    <MarketSkeletons v-if="!done" />
                </div>
            </div>
        </div>
        <div class="blur bottom"></div>
    </main>
</template>

<style lang="scss" scoped>
.timeline-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
}

.timeline {
    height: 100%;
    overflow-y: auto;
}

.blur {
    mask-image: linear-gradient(var(--deg), hsla(0, 0%, 100%, 0) 0, #fff 75%);
    backdrop-filter: blur(4px);
    width: 100%;
    position: fixed;
}

.top {
    top: 0;
    height: 2rem;
    --deg: 0deg;
}

.bottom {
    bottom: 0;
    height: 4rem;
    --deg: 180deg;
}
</style>
