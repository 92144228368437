<script setup lang="ts">
import type { MarketWithData } from "@/database/entities/market";
import type { PonderOrder } from "@/database/entities/ponder";
import type { User } from "@/database/entities/user";
import { trackUmamiEvent } from "@jaseeey/vue-umami-plugin";
import NumberFlow from "@number-flow/vue";
import {
	PhArrowClockwise,
	PhArrowLineUp,
	PhAt,
	PhCalendarDot,
	PhChatCircleText,
	PhCircleNotch,
	PhCoins,
	PhHeart,
	PhRepeat,
} from "@phosphor-icons/vue";
import { UseTimeAgo } from "@vueuse/components";
import { useInfiniteScroll } from "@vueuse/core";
import DOMPurify from "dompurify";
import Accordion from "primevue/accordion";
import AccordionContent from "primevue/accordioncontent";
import AccordionHeader from "primevue/accordionheader";
import AccordionPanel from "primevue/accordionpanel";
import ButtonGroup from "primevue/buttongroup";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Fluid from "primevue/fluid";
import type { Address } from "viem";
import { nextTick, onMounted, ref, useTemplateRef, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { api } from "~/inertia/api";
import { useAuthenticationStore } from "~/stores/authentication";
import { useWalletStore } from "~/stores/wallet";
import { addressToUser } from "~/utils/addressToUser";
import {
	creatorResolve,
	getTokenBalances,
	placeOrder,
	redeemConditionalTokens,
	resolveMarket,
	sponsorCancelOrder,
	sponsorPlaceOrder,
	sponsorRedeemTokens,
} from "~/utils/onchainActions";
import { ChartIntervals, type Trade } from "~/utils/types";

const tradeHistory = useTemplateRef("tradeHistory");
const marketChart = useTemplateRef("marketChart");

const route = useRoute();
const i18n = useI18n();
const authenticationStore = useAuthenticationStore();
const walletStore = useWalletStore();

const market = ref<MarketWithData>();
const author = ref<User>();
const liked = ref<boolean>(false);
const empty = ref<boolean>(false);
const graphEmpty = ref<boolean>(true);
const selectedOption = ref<string>("");
const price = ref<number>();
const quantity = ref<number>();
const ready = ref<boolean>(false);
const placeOrderButtonText = ref<string>(i18n.t("market.placeOrder"));
const loading = ref<boolean>(false);
const yesBalanceValue = ref<number>(0);
const noBalanceValue = ref<number>(0);
const totalVolume = ref<number>(0);
const canSell = ref<boolean>(false);
const orderType = ref("Buy");
const orderTypes = ref(["Buy", "Sell"]);
const trades = ref<Trade[]>([]);
const canBeResolvedByCreator = ref<boolean>(false);
const canBeResolved = ref<boolean>(false);
const refreshLoading = ref<boolean>(false);
const myOrders = ref<PonderOrder[]>([]);
const currentInterval = ref<ChartIntervals>(ChartIntervals.All);
const contentViewKey = ref<number>(0);

let offset = 0;
let allTradesLoaded = false;

const { reset } = useInfiniteScroll(
	tradeHistory,
	async () => {
		if (allTradesLoaded) return;
		const tradeReq = await api
			.market({
				id: route.params.id.toString(),
			})
			.trades.get({
				query: {
					offset,
				},
			});
		if (tradeReq.error) {
			allTradesLoaded = true;
			console.error(tradeReq.error.status, tradeReq.error.value);
			return;
		}
		if (tradeReq.data?.length <= 0) {
			allTradesLoaded = true;
			return;
		}
		offset += tradeReq.data?.length;
		for (const trade of tradeReq.data) {
			if (!trade.id) continue;
			const normalizedTrade = {
				...trade,
				id: trade.id.id,
				buy_order: {
					...trade.buy_order,
					traderUser: await addressToUser(
						(trade.buy_order as PonderOrder).trader,
					),
				},
				sell_order: {
					...trade.sell_order,
					traderUser: await addressToUser(
						(trade.sell_order as PonderOrder).trader,
					),
				},
				quantity: trade.quantity as number,
				price: trade.price as number,
			};
			// @ts-ignore
			trades.value.push(normalizedTrade);
		}
	},
	{ distance: 10 },
);

const chartOptions = ref({
	layout: {
		textColor: "white",
		background: { type: "transparent", color: "#00000000" },
		// attributionLogo: false,
	},
	grid: {
		vertLines: {
			color: "rgba(204, 255, 237, 0.2)",
			// visible: false,
		},
		horzLines: {
			color: "rgba(204, 255, 237, 0.2)",
			// visible: false,
		},
	},
	// handleScroll: {
	// 	mouseWheel: false,
	// 	pressedMouseMove: false,
	// 	horizTouchDrag: false,
	// 	vertTouchDrag: false,
	// },
	handleScroll: false,
	handleScale: {
		axisPressedMouseMove: false,
		pinch: false,
	},
	kineticScroll: {
		touch: false,
		mouse: false,
	},
	crosshair: {
		mode: 0,
		horizLine: {
			color: "#00000000",
		},
		vertLine: {
			width: 6,
			color: "rgba(255, 255, 255, 0.2)",
			style: 0,
			labelBackgroundColor: "rgb(54, 114, 106)",
		},
		horzLine: {
			width: 1,
			color: "rgba(255, 255, 255, 0.2)",
			labelBackgroundColor: "rgb(25, 52, 48)",
		},
	},
});
const yesSeriesOptions = ref({
	color: "#00F29C",
	lineWidth: 3,
	title: i18n.t("market.yes"),
	pointMarkersVisible: false,
	lastPriceAnimation: 1,
});
const noSeriesOptions = ref({
	color: "#D8605A",
	lineWidth: 3,
	title: i18n.t("market.no"),
	pointMarkersVisible: false,
	lastPriceAnimation: 1,
});
const timeScaleOptions = ref({
	timeVisible: true,
	secondsVisible: false,
	rightOffset: 0.5,
	tickMarkFormatter: (time: number) => {
		const date = new Date(time * 1000);
		const hours = date.getHours();
		const minutes = date.getMinutes();
		const ampm = hours >= 12 ? "PM" : "AM";
		const formattedHours = hours % 12 || 12; // Convert 24h to 12h format
		const formattedMinutes = minutes.toString().padStart(2, "0");

		return `${formattedHours}:${formattedMinutes} ${ampm}`;
	},
});
const priceScaleOptions = ref({
	mode: 1,
	minValue: 0,
	maxValue: 1,
	// autoScale: false,
	// scaleMargins: {
	// 	top: 0.3,
	// 	bottom: 0.05,
	// },
});

const commentsBlock = useTemplateRef("commentsBlock");
const tab = ref<number>(1);
const tabOptions = ref([
	{ name: i18n.t("market.tabs.graph"), context: { active: true }, value: 1 },
	{ name: i18n.t("market.tabs.orderBook"), value: 3 },
	{ name: i18n.t("market.tabs.tradeHistory"), value: 4 },
]);

function toggleOption(option: string) {
	selectedOption.value = option;
	if (!market.value?.address) return;
	if (!market.value?.bestPrices || loading.value) return;
	if (option === "yes") {
		price.value = market.value.buyYes;
		canSell.value = yesBalanceValue.value > 0;
		if (canSell.value && orderType.value === "Sell") {
			price.value = market.value.sellYes;
		}
	} else if (option === "no") {
		price.value = market.value.buyNo;
		canSell.value = noBalanceValue.value > 0;
		if (canSell.value && orderType.value === "Sell") {
			price.value = market.value.sellNo;
		}
	}
}

function setMax() {
	if (orderType.value === "Sell") {
		if (selectedOption.value === "yes") {
			quantity.value = yesBalanceValue.value;
		} else {
			quantity.value = noBalanceValue.value;
		}
	}
}

function getCost() {
	if (!price.value || !quantity.value) return 0;
	const priceCost = price.value / 100;
	return priceCost * quantity.value;
}

watch([selectedOption, price, quantity, canSell, orderType], () => {
	if (
		selectedOption.value !== "" &&
		price.value &&
		quantity.value &&
		price.value > 0 &&
		quantity.value > 0
	) {
		ready.value = true;
	} else {
		ready.value = false;
	}
});

async function placeOrderOnMarket() {
	if (
		!ready.value ||
		!price.value ||
		!quantity.value ||
		!market.value?.address
	) {
		return;
	}
	loading.value = true;
	placeOrderButtonText.value = i18n.t("market.placeOrderLoading");
	let hash: string;
	const numberQuantity = Number(quantity.value) * 10000;
	const outcome = selectedOption.value === "yes";
	//TODO: update to when new market implementation is live
	if (
		new Date(market.value.created_at).getTime() >
		new Date(2024, 10, 1, 12, 30, 0).getTime()
	) {
		if (orderType.value === "Buy") {
			hash = await sponsorPlaceOrder(
				market.value.address as Address,
				numberQuantity,
				price.value,
				outcome,
				true,
			);
		} else {
			hash = await sponsorPlaceOrder(
				market.value.address as Address,
				numberQuantity,
				price.value,
				outcome,
				false,
			);
		}
	} else {
		if (orderType.value === "Buy") {
			hash = await sponsorPlaceOrder(
				market.value.address as Address,
				numberQuantity,
				price.value,
				outcome,
				true,
			);
		} else {
			hash = await placeOrder(
				market.value.address as Address,
				numberQuantity,
				price.value,
				outcome,
				false,
			);
		}
	}
	placeOrderButtonText.value = i18n.t("market.placeOrderSuccess");
	loading.value = false;
	trackUmamiEvent("place-order", {
		address: market.value.address,
		outcome: outcome,
		quantity: numberQuantity,
		price: price.value,
		orderType: orderType.value,
	});
	orderType.value = "Buy";
	quantity.value = undefined;
	price.value = undefined;
	await getMarketData();
	await getConditionalTokenBalances();
	contentViewKey.value++;
	empty.value = false;
	placeOrderButtonText.value = i18n.t("market.placeOrder");
}

async function manuallyResolveMarket(outcome: boolean) {
	if (!canBeResolvedByCreator.value && market.value) {
		return;
	}
	loading.value = true;
	await creatorResolve(
		market.value?.address as Address,
		false, // TODO: false until we have pause button
		true, // TODO: if market creator ever has ability to unresolve market
		outcome,
	);
	loading.value = false;
	canBeResolved.value = false;
	await getMarketData();
	trackUmamiEvent("resolve-market", {
		source: "creator",
	});
}

async function redeemWinnings() {
	if (!market.value?.closed) return;
	loading.value = true;
	//TODO: update to when new market implementation is live
	if (
		new Date(market.value.created_at).getTime() >
		new Date(2024, 10, 1, 12, 30, 0).getTime()
	) {
		await sponsorRedeemTokens(
			market.value?.address as Address,
			market.value.outcome
				? Number(yesBalanceValue.value) * 10000
				: Number(noBalanceValue.value) * 10000,
		);
	} else {
		await redeemConditionalTokens(
			market.value?.address as Address,
			market.value.outcome
				? Number(yesBalanceValue.value) * 10000
				: Number(noBalanceValue.value) * 10000,
		);
	}
	loading.value = false;
}

async function cancelOrderOnMarket(orderId: string) {
	if (!market.value?.address) return;
	loading.value = true;
	await sponsorCancelOrder(
		market.value.address as Address,
		Number(orderId.split("_")[1]),
	);
	loading.value = false;
	trackUmamiEvent("cancel-order", {
		address: market.value.address,
		orderId: orderId,
	});
}

async function communityResolveMarket() {
	if (!canBeResolved.value && market.value) {
		return;
	}
	loading.value = true;
	await resolveMarket(market.value?.address as Address);
	loading.value = false;
	canBeResolved.value = false;
	trackUmamiEvent("resolve-market", {
		source: "community",
	});
}

function performLike() {
	if (liked.value) {
		api.market({
			id: route.params.id.toString(),
		})
			.unlike.post()
			.then((result) => {
				if (result.data?.ok) {
					liked.value = false;
				}
			});
	} else {
		api.market({
			id: route.params.id.toString(),
		})
			.like.post()
			.then((result) => {
				if (result.data?.ok) {
					liked.value = true;
				}
			});
	}
}

function gotoComments() {
	commentsBlock.value?.scrollIntoView({ behavior: "smooth" });
}

function timeAgoToDateTime(d: Date) {
	return `${new Date(d).toLocaleDateString()} ${new Date(d).toLocaleTimeString()}`;
}

function getOrderStatus(order: PonderOrder) {
	if (
		!order.closed_at ||
		new Date(order.closed_at).getTime() === new Date(0).getTime()
	) {
		return i18n.t("market.myOrders.statuses.open");
	}
	if (order.quantity !== order.filled) {
		return i18n.t("market.myOrders.statuses.cancelled");
	}
	if (order.filled > 0 && order.filled < order.quantity) {
		return i18n.t("market.myOrders.statuses.partial");
	}
	return i18n.t("market.myOrders.statuses.filled");
}

async function getConditionalTokenBalances() {
	if (!market.value) return;
	const { yesBalance, noBalance } = await getTokenBalances(
		market.value.address as Address,
	);
	yesBalanceValue.value = Number(yesBalance) / 10000;
	noBalanceValue.value = Number(noBalance) / 10000;
	if (authenticationStore.user?.id) {
		canBeResolvedByCreator.value =
			market.value.created_by === authenticationStore.user.id.id &&
			market.value.outcome === undefined;
		canBeResolved.value =
			market.value.created_by !== authenticationStore.user.id.id &&
			market.value.outcome === undefined &&
			new Date().getTime() >=
				new Date(market.value.closes_at).getTime() +
					24 * 60 * 60 * 1000;
	}
}

function setChartInterval(interval: ChartIntervals) {
	if (!marketChart.value) return;
	currentInterval.value = interval;
	marketChart.value.setChartInterval(interval);
}

async function getMarketData() {
	refreshLoading.value = true;
	const marketData = await api
		.market({ id: route.params.id.toString() })
		.get();
	if (marketData.error) {
		refreshLoading.value = false;
		return;
	}
	market.value = marketData.data;
	totalVolume.value = marketData.data.totalVolume;
	if (authenticationStore.isAuthenticated()) {
		const myOrdersData = await api
			.market({ id: route.params.id.toString() })
			["my-orders"].get();
		myOrders.value = myOrdersData.data as PonderOrder[];
		if (tabOptions.value.length === 3) {
			tabOptions.value.splice(1, 0, {
				name: i18n.t("market.tabs.myOrders"),
				value: 2,
			});
		}
	}
	const userData = await api.user({ id: market.value.created_by }).get();
	author.value = userData.data ?? undefined;
	if (authenticationStore.isAuthenticated()) {
		const likedData = await api
			.market({ id: route.params.id.toString() })
			.liked.get();
		if (likedData.error) {
			liked.value = false;
		} else {
			liked.value = likedData.data.likes;
		}
	}
	if (route.query.comments) {
		nextTick(() => {
			gotoComments();
		});
	}
	const orderCountReq = await api
		.market({
			id: route.params.id.toString(),
		})
		.ordercount.get();
	if (orderCountReq.data === 0 || orderCountReq.data === null) {
		empty.value = true;
	}
	const latestTradeReq = await api
		.market({
			id: route.params.id.toString(),
		})
		.trades.get({
			query: {
				offset: 0,
				limit: 1,
			},
		});
	if (latestTradeReq.data?.length !== 0) {
		graphEmpty.value = false;
	}
	if (market.value.address) {
		await getConditionalTokenBalances();
	}
	refreshLoading.value = false;
	if (!market.value.comments) return;
}

watch(market, async (newData) => {
	if (!newData?.address) {
		setTimeout(async () => {
			await getMarketData();
			await getConditionalTokenBalances();
			contentViewKey.value++;
		}, 750);
	}
});

onMounted(async () => {
	await getMarketData();
});
</script>

<template>
    <main>
        <header v-if="market">
            <UserDetails :id="market.created_by" />
        </header>
        <section class="content" v-if="market">
            <section class="prediction">
                <h2 class="question">
                    {{ market.question }}
                </h2>

                <section
                    class="description"
                    v-if="market.description"
                    v-html="DOMPurify.sanitize(market.description, { USE_PROFILES: { html: true } })"
                ></section>

                <div class="interactions">
                    <InteractButton
                        :label="$t('interactions.like')"
                        :state="liked"
                        :count="(market.likes?.length ?? 0) + (liked ? 1 : 0)"
                        :icon="PhHeart"
                        @click="performLike"
                    />
                    <InteractButton
                        :label="$t('interactions.goToReplies')"
                        :ripple="false"
                        :state="false"
                        :count="market.comments?.length ?? 0"
                        :icon="PhChatCircleText"
                        @click="gotoComments"
                    />
                </div>

                <div class="info">
                    <div class="date" v-tooltip.bottom="timeAgoToDateTime(new Date(market.closes_at))">
                        <PhCalendarDot weight="bold" />
                        <UseTimeAgo v-if="market.resolved_at" v-slot="{ timeAgo }" :time="market.resolved_at">
                            {{ $t("market.closing.closed", { date: timeAgo }) }}
                        </UseTimeAgo>
                        <UseTimeAgo v-else v-slot="{ timeAgo }" :time="market.closes_at">
                            {{
                                new Date(market.closes_at).getTime() < new Date().getTime() || timeAgo.includes("now")
                                    ? $t("market.closing.closed", { date: timeAgo })
                                    : timeAgo.startsWith("in")
                                      ? $t("market.closing.closes", { date: timeAgo })
                                      : $t("market.closing.openUntil", { date: timeAgo })
                            }}
                        </UseTimeAgo>
                    </div>
                    <div class="volume">
                        <PhCoins weight="bold" />
                        <NumberFlow
                            :value="totalVolume / 100"
                            :format="{ style: 'currency', currency: 'USD' }"
                            :prefix="$t('market.tv')"
                        />
                    </div>
                </div>

                <section class="graph-tabs">
                    <SelectButton
                        v-model="tab"
                        :allowEmpty="false"
                        :options="tabOptions"
                        optionLabel="name"
                        optionValue="value"
                        aria-labelledby="basic"
                    />
                    <Button class="p-button-outlined refreshButton" @click="getMarketData" :disabled="refreshLoading">
                        <PhCircleNotch v-if="refreshLoading" class="spinning" size="1.5em" />
                        <PhArrowClockwise v-else size="1.5em" />
                    </Button>
                </section>

                <div v-if="empty" class="contentViews">
                    <section class="empty">
                        <p>{{ $t("empty") }}</p>
                    </section>
                </div>
                <div v-else class="contentViews" :key="contentViewKey">
                    <div v-if="tab === 1 && !graphEmpty">
                        <div style="height: 24rem">
                            <MarketChart
                                :autosize="true"
                                :yes-data="market.yesOrders"
                                :no-data="market.noOrders"
                                :chart-options="chartOptions"
                                :yes-series-options="yesSeriesOptions"
                                :no-series-options="noSeriesOptions"
                                :time-scale-options="timeScaleOptions"
                                :price-scale-options="priceScaleOptions"
                                :key="contentViewKey"
                                ref="marketChart"
                            />
                        </div>
                        <ButtonGroup>
                            <Button
                                v-for="interval in ChartIntervals"
                                :key="interval"
                                @click="setChartInterval(interval)"
                                :severity="currentInterval === interval ? 'contrast' : 'secondary'"
                                v-vibrate="1"
                                style="margin-top: 1rem"
                                rounded
                            >
                                {{ interval }}
                            </Button>
                        </ButtonGroup>
                    </div>
                    <div v-if="tab === 1 && graphEmpty" class="contentViews">
                        <section class="empty">
                            <p>{{ $t("market.graphEmpty") }}</p>
                        </section>
                    </div>
                    <div v-else-if="tab === 2">
                        <DataTable :value="myOrders" style="margin-top: 1em">
                            <Column sortable field="is_buy" :header="$t('market.myOrders.type')">
                                <template #body="slotProps">
                                    {{ slotProps.data.is_buy ? $t("market.buy") : $t("market.sell") }}
                                </template>
                            </Column>
                            <Column sortable field="price" :header="$t('market.myOrders.price')">
                                <template #body="slotProps"> {{ slotProps.data.price }}&#162; </template>
                            </Column>
                            <Column sortable field="quantity" :header="$t('market.myOrders.quantity')">
                                <template #body="slotProps">
                                    {{
                                        slotProps.data.filled > 0 && slotProps.data.filled < slotProps.data.quantity
                                            ? `${Number(slotProps.data.filled) / 10000}/${
                                                  Number(slotProps.data.quantity) / 10000
                                              }`
                                            : Number(slotProps.data.quantity) / 10000
                                    }}
                                </template>
                            </Column>
                            <Column sortable field="outcome" :header="$t('market.myOrders.outcome')">
                                <template #body="slotProps">
                                    {{ slotProps.data.outcome ? $t("market.yes") : $t("market.no") }}
                                </template>
                            </Column>
                            <Column :header="$t('market.myOrders.status')">
                                <template #body="slotProps">
                                    {{ getOrderStatus(slotProps.data) }}
                                </template>
                            </Column>
                            <Column>
                                <template #body="{ data }">
                                    <Button
                                        v-if="
                                            !data.closed_at ||
                                            new Date(data.closed_at).getTime() === new Date(0).getTime()
                                        "
                                        @click="cancelOrderOnMarket(data.id.id)"
                                        :disabled="loading"
                                        style="margin-left: 0.5em"
                                        v-vibrate="1"
                                    >
                                        <PhCircleNotch v-if="loading" class="spinning" size="1.5em" />
                                        {{ $t("market.cancelOrder") }}
                                    </Button>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                    <OrderBooks v-else-if="tab === 3" :id="route.params.id.toString()"></OrderBooks>
                    <div ref="tradeHistory" class="tradeHistory" v-else-if="tab === 4">
                        <div v-for="trade in trades" class="tradeLine">
                            <div v-for="i in [trade.sell_order, trade.buy_order]">
                                <div class="profileLine">
                                    <ProfileIcon :id="i.traderUser?.id.id.toString()" size="normal" />
                                    <RouterLink
                                        class="username usernameMargin"
                                        :to="'/@' + i.traderUser?.name"
                                        @click.stop
                                    >
                                        <PhAt weight="bold" />{{ i.traderUser.name }}
                                    </RouterLink>
                                </div>
                                traded {{ trade.quantity }} share{{ trade.quantity > 1 ? "s" : "" }} of
                                {{ i.outcome ? $t("market.yes") : $t("market.no") }}
                                at ${{ (trade.price / trade.quantity / 100).toFixed(2) }}
                                <br />
                                <UseTimeAgo class="tradeTime" v-slot="{ timeAgo }" :time="trade.timestamp">
                                    {{ timeAgo }}
                                </UseTimeAgo>
                            </div>
                        </div>
                    </div>
                </div>

                <Accordion style="margin-top: 1rem" value="0">
                    <AccordionPanel value="0">
                        <AccordionHeader>{{ $t("market.rules") }}</AccordionHeader>
                        <AccordionContent>
                            <p
                                v-linkify:options="{
                                    target: '_blank',
                                    attributes: {
                                        onclick: 'event.stopPropagation()',
                                    },
                                    className: 'link',
                                }"
                            >
                                {{ market.rules }}
                            </p>
                        </AccordionContent>
                    </AccordionPanel>
                </Accordion>
            </section>

            <section class="interact">
                <div
                    v-if="market && (market.closed || new Date(market.closes_at).getTime() <= new Date().getTime())"
                    class="resolution"
                >
                    <div v-if="market.outcome !== undefined">
                        <div class="outcome">
                            <span>{{ $t("market.outcome") }}</span>
                            <OutcomeText :outcome="market.outcome" />
                            <Button
                                v-if="
                                    (market.outcome === true && yesBalanceValue > 0) ||
                                    (market.outcome === false && noBalanceValue > 0)
                                "
                                v-ripple
                                @click="redeemWinnings"
                                :disabled="loading"
                            >
                                <PhCircleNotch v-if="loading" class="spinning" size="1.5em" />
                                {{ $t("market.redeemWinnings") }}
                            </Button>
                        </div>
                    </div>
                    <div v-else>
                        <p>{{ $t("market.closedAndWaiting") }}</p>
                    </div>
                </div>
                <div
                    class="answers"
                    v-if="market && !(market.closed || new Date(market.closes_at).getTime() <= new Date().getTime())"
                >
                    <MarketButton
                        :percentage="market.yesPercentage"
                        @click="toggleOption('yes')"
                        :highlighted="selectedOption === 'yes'"
                        :label="$t('market.yes')"
                        color="good"
                    />
                    <MarketButton
                        :percentage="market.noPercentage"
                        @click="toggleOption('no')"
                        :highlighted="selectedOption === 'no'"
                        :label="$t('market.no')"
                        color="bad"
                    />
                </div>
                <div
                    class="order"
                    v-if="market && !(market.closed || new Date(market.closes_at).getTime() <= new Date().getTime())"
                >
                    <SelectButton v-model="orderType" :options="orderTypes" :disabled="!canSell || loading" />
                    <Fluid class="amount">
                        <FloatLabel variant="on">
                            <InputNumber
                                v-model="price"
                                id="price"
                                :max="99"
                                :min="0"
                                :max-length="2"
                                :maxFractionDigits="0"
                                suffix="&#162;"
                                :disabled="!selectedOption || loading"
                            />
                            <label for="price">{{ $t("market.price") }}</label>
                        </FloatLabel>
                        <FloatLabel variant="on">
                            <InputNumber
                                v-if="orderType === 'Buy'"
                                id="quantity"
                                v-model="quantity"
                                :disabled="!selectedOption || loading"
                            />
                            <InputGroup v-else-if="orderType === 'Sell'">
                                <InputNumber
                                    id="quantity"
                                    v-model="quantity"
                                    placeholder="1"
                                    :disabled="!selectedOption || loading"
                                    :min="0"
                                    :max="selectedOption === 'yes' ? yesBalanceValue : noBalanceValue"
                                />
                                <Button style="padding: 0 0.2em" @click="setMax">
                                    <PhArrowLineUp size="1em" style="padding: 0; margin: 0" />
                                </Button>
                            </InputGroup>
                            <label for="quantity">{{ $t("market.quantity") }}</label>
                        </FloatLabel>
                    </Fluid>
                    <span class="cost" v-if="price && quantity"
                        >{{ orderType === "Buy" ? $t("market.cost") : $t("market.payout") }} ${{
                            getCost().toFixed(2)
                        }}</span
                    >
                    <Button
                        v-auth
                        v-ripple
                        class="placeOrder"
                        :disabled="
                            !ready ||
                            loading ||
                            (orderType === 'Buy' &&
                                walletStore.usdcBalance !== undefined &&
                                getCost() > walletStore.usdcBalance)
                        "
                        @click="placeOrderOnMarket"
                        v-vibrate="10"
                    >
                        <PhCircleNotch v-if="loading" class="spinning" size="1.5em" />
                        {{
                            orderType === "Buy" &&
                            walletStore.usdcBalance !== undefined &&
                            getCost() > walletStore.usdcBalance
                                ? $t("insufficientBalance")
                                : placeOrderButtonText
                        }}
                    </Button>
                </div>
                <div class="resolve" v-if="canBeResolvedByCreator || canBeResolved">
                    <p>{{ $t("market.resolve") }}</p>
                    <div v-if="canBeResolvedByCreator" class="buttons">
                        <Button
                            @click="manuallyResolveMarket(true)"
                            :disabled="loading"
                            style="background-color: var(--mint-900); color: var(--fg); border-color: var(--mint-950)"
                            v-vibrate="1"
                        >
                            <PhCircleNotch v-if="loading" class="spinning" size="1.5em" />
                            {{ $t("market.resolveTo", { outcome: $t("market.yes") }) }}
                        </Button>
                        <Button
                            @click="manuallyResolveMarket(false)"
                            style="
                                background-color: var(--cinnamon-900);
                                color: var(--fg);
                                border-color: var(--cinnamon-950);
                            "
                            :disabled="loading"
                            v-vibrate="1"
                        >
                            <PhCircleNotch v-if="loading" class="spinning" size="1.5em" />
                            {{ $t("market.resolveTo", { outcome: $t("market.no") }) }}
                        </Button>
                    </div>
                    <div v-if="canBeResolved" class="buttons">
                        <Button @click="communityResolveMarket" :disabled="loading" v-vibrate="1">
                            <PhCircleNotch v-if="loading" class="spinning" size="1.5em" />
                            {{ $t("market.resolveToMajority") }}
                        </Button>
                    </div>
                </div>
            </section>
        </section>
        <section class="comments" ref="commentsBlock">
            <Comments v-if="market?.id" :market-id="market.id.id.toString()" :comments-data="market.comments ?? []" />
        </section>
    </main>
</template>

<style lang="scss" scoped>
header {
    margin-bottom: 1em;
}

section.content {
    display: flex;
    gap: 2em;

    @media (max-width: 1050px) {
        max-width: 70vw;
        flex-direction: column;
    }

    @media (max-width: 850px) {
        max-width: 60vw;
    }

    @media (max-width: 600px) {
        max-width: unset !important;
    }

    .prediction {
        flex: 2;
        display: flex;
        gap: 1em;
        flex-direction: column;
        border-radius: 1em;

        h2 {
            margin: 0;
        }

        .description,
        .empty {
            padding: 1em;
            border-radius: 0.5em;
            background: var(--darkGray-500);
        }

        .info {
            display: flex;
            gap: 1em;

            > div {
                display: flex;
                gap: 1em;
                flex-direction: row;
                background: var(--darkGray-600);
                padding: 0.5em;
                border-radius: 0.5em;

                p {
                    margin: 0;
                }
            }
        }

        .graph-tabs {
            display: flex;
            gap: 1em;
            justify-content: space-between;
        }

        :deep(p) {
            margin: 0;
        }
    }

    .interact {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 1em;
        padding: 1em;
        border-radius: 1em;
        height: fit-content;
        min-width: 15rem;
        max-width: 25rem;
        background: linear-gradient(45deg, var(--darkMint-900), var(--darkMint-950));

        .answers,
        .order,
        .resolve {
            display: flex;
            flex-direction: column;
            gap: 1em;
        }

        .answers button {
            margin: 0;
        }

        .order .amount {
            display: flex;
            flex-direction: row;
            gap: 1em;
        }

        .resolve {
            p {
                margin: 0;
            }

            .buttons {
                display: flex;
                flex-direction: row;
                gap: 1em;

                button {
                    flex: 1;
                }
            }
        }

        .resolution {
            display: flex;
            flex-direction: column;
            gap: 1em;

            .outcome {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.4em;
            }
        }
    }
}

section.comments {
    margin-top: 2em;
}

.tradeLine {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1em;
    gap: 1em;
}

.profileLine {
    display: flex;
    flex-direction: row;
}

.tradeTime {
    color: var(--darkGray-300);
    margin-left: 0.25em;
}

.username {
    display: flex;
    align-items: center;
    gap: 0.5em;
    color: var(--fg);
    background: var(--mint-900);
    padding: 0.1em 0.5em;
    border-radius: 99px;
    width: min-content;

    &.usernameMargin {
        margin: 0 0.5em;
    }
}

.tradeHistory {
    max-height: 250px;
    overflow-y: scroll;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.spinning {
    animation: spin 1s linear infinite;
}
</style>
