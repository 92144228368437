<script setup lang="ts">
import { computed, ref } from "vue";
import { onMounted } from "vue";
import { useAuthenticationStore } from "~/stores/authentication";
import { normalizeComment } from "~/utils/comment";
import type { Comment, RawComment } from "~/utils/types";

const isAuthenticated = computed(() =>
	useAuthenticationStore().isAuthenticated(),
);

const comments = ref<Comment[]>([]);

const props = defineProps<{
	marketId: string;
	commentsData: RawComment[];
}>();

onMounted(async () => {
	const normalizedArray: Comment[] = [];
	for (const comment of props.commentsData) {
		const normalized = await normalizeComment(comment);
		normalizedArray.push(normalized);
	}
	comments.value = normalizedArray;
});

function addComment(comment: {
	comment: Comment;
	parentId: string;
	parentType: "market" | "comment";
}) {
	if (comment.parentType === "market") {
		comments.value.unshift(comment.comment);
	} else if (comment.parentType === "comment") {
		const ind = comments.value.findIndex(
			(v) => v.id.id === comment.parentId,
		);
		comments.value[ind].comments?.push(comment.comment);
	}
}
</script>

<template>
    <section class="comments">
        <CommentCompose v-if="isAuthenticated" @new="addComment" parentType="market" :parentId="props.marketId" />
        <hr v-if="isAuthenticated" />
        <div
            v-if="comments.length > 0"
            v-for="(comment, index) in comments"
            class="commentBlock"
            :key="comment.id.id.toString()"
        >
            <Comment
                :hasReplies="comment.comments && comment.comments.length > 0"
                :id="comment.id.id.toString()"
                :author="comment.author?.id.id.toString()"
                :authorName="comment.author?.display_name"
                :authorUsername="comment.author?.name"
                :text="comment.content"
                :createdAt="comment.created_at"
                :replyCount="comment.comments?.length ?? 0"
                :likeCount="comment.likes?.length ?? 0"
                @newReply="addComment"
            />
            <Comment
                v-if="comment.comments"
                v-for="(reply, replyIndex) in comment.comments"
                reply
                :lastReply="!comment.comments[replyIndex + 1]"
                :id="reply.id.id.toString()"
                :author="reply.author?.id.id.toString()"
                :authorName="reply.author?.display_name"
                :authorUsername="reply.author?.name"
                :text="reply.content"
                :likeCount="comment.likes?.length ?? 0"
                :createdAt="reply.created_at"
            />
            <hr v-if="comments[index + 1]" />
        </div>
        <div v-else>
            {{ $t("comments.none") }}
        </div>
    </section>
</template>

<style lang="scss" scoped>
.comments {
    background: var(--darkGray-600);
    padding: 1em;
    border-radius: 0.5em;
    display: flex;
    flex-direction: column;
    gap: 1em;

    hr {
        border: none;
        height: 1px;
        background-color: var(--darkGray-400);
        margin: 0;
    }
}
</style>
