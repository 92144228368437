<script setup lang="ts">
import NumberFlow from "@number-flow/vue";
import {
	PhDoorOpen,
	PhDotsThree,
	PhPaperPlaneRight,
	PhWallet,
} from "@phosphor-icons/vue";
import Popover from "primevue/popover";
import { useDialog } from "primevue/usedialog";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useAuthenticationStore } from "~/stores/authentication";
import { useWalletStore } from "~/stores/wallet";
import Transfer from "~/views/Transfer.vue";

const authenticationStore = useAuthenticationStore();
const walletStore = useWalletStore();
const i18n = useI18n();

const op = ref();

const toggle = (event: any) => {
	op.value.toggle(event);
};

const dialog = useDialog();

function showTransfer() {
	dialog.open(Transfer, {
		props: {
			header: i18n.t("transfer.transfer"),
			modal: true,
		},
	});
}

async function copyWalletAddress() {
	if (walletStore.walletAddress) {
		await navigator.clipboard.writeText(
			walletStore.walletAddress.toString(),
		);
		op.value.toggle();
	}
}

const balancesLoading = ref<boolean>(true);

watch(
	() => walletStore.usdcBalance,
	() => {
		balancesLoading.value = false;
	},
);
</script>

<template>
    <div class="wrapper" v-if="authenticationStore.isAuthenticated()">
        <div class="balances" v-if="walletStore.walletAddress">
            <div class="balance" style="color: var(--mint-200)">
                <Skeleton v-if="balancesLoading" height="1rem" width="3rem"></Skeleton>
                <NumberFlow
                    v-else
                    :value="walletStore.usdcBalance ?? 0"
                    :format="{
                        style: 'currency',
                        currency: 'USD',
                    }"
                />
            </div>
        </div>
        <div class="profile">
            <ProfileIcon class="profileIcon" is-me />
            <div class="details">
                <span class="display_name">{{ authenticationStore.user?.name }}</span>
                <span class="username">{{ authenticationStore.user?.name }}</span>
            </div>
            <div type="button" @click="toggle" class="dots">
                <PhDotsThree weight="bold" size="1.5em" />
            </div>
            <Popover ref="op" unstyled>
                <div class="popover">
                    <Button @click="showTransfer" role="button" :label="$t('transfer.transfer')">
                        <PhPaperPlaneRight /><span> {{ $t("transfer.transfer") }} </span>
                    </Button>
                    <Button
                        v-if="walletStore.walletAddress"
                        @click="copyWalletAddress"
                        role="button"
                        :label="$t('copyWalletAddress')"
                    >
                        <PhWallet /><span> {{ $t("copyWalletAddress") }} </span>
                    </Button>
                    <CoinbaseButton />
                    <!-- <RouterLink to="/settings">
                        <Button role="button" label="Settings" style="width: 100%">
                            <PhGearSix /><span>{{ $t("settings") }}</span>
                        </Button>
                    </RouterLink> -->
                    <Button @click="authenticationStore.logout" role="button" class="logout" :label="$t('logout')">
                        <PhDoorOpen /><span> {{ $t("logout") }} </span>
                    </Button>
                </div>
            </Popover>
        </div>
    </div>
    <Button v-else v-auth role="button" class="signup" :label="$t('loginSignup')">
        <PhDoorOpen /><span> {{ $t("loginSignup") }} </span>
    </Button>
</template>

<style lang="scss" scoped>
.popover {
    display: flex;
    background: var(--acrylicPanel);
    padding: 1rem;
    backdrop-filter: blur(10px) brightness(0.4);
    border-radius: 12px;
    flex-direction: column;
    gap: 0.5em;
    transform-origin: bottom;
    animation: slide-in 0.15s ease-in;

    @keyframes slide-in {
        0% {
            opacity: 0;
            transform: scaleY(0.5);
        }

        100% {
            opacity: 1;
            transform: scaleY(1);
        }
    }

    @media (max-width: 600px) {
        transform-origin: top;
    }

    button {
        background-color: var(--mint-950);
        border-color: var(--mint-900);
        color: var(--fg);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-basis: auto;

        &:hover {
            background-color: var(--mint-900);
            color: var(--fg);
        }

        &.logout {
            background-color: var(--cinnamon-950);
            border-color: var(--cinnamon-950);

            &:hover {
                background-color: var(--cinnamon-900);
                border-color: var(--fg);
                color: var(--fg);
            }
        }
    }
}

.balances {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    margin-top: 1em;
    margin-left: 5em;

    .balance {
        display: flex;
        flex-direction: row;
        gap: 0.5em;
    }
}

.profile {
    bottom: 1rem;
    left: auto;
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
    justify-content: end;

    .dots {
        cursor: pointer;
        margin-left: 0.5rem;
    }

    .details {
        display: flex;
        flex-direction: column;
        gap: 0.5em;

        .display_name {
            font-weight: bold;
        }

        .username {
            font-size: 0.8em;
            font-weight: normal;
        }
    }
}

.signup {
    margin-left: 1.5rem;
    margin-bottom: 2rem;
}

@media (max-width: 1050px) {
    .profile {
        flex-direction: column-reverse;

        .profileIcon {
            width: 3rem;
            height: 3rem;
        }

        .details {
            display: none;
        }

        .balances {
            transform: translate(-3rem, -1rem);
        }

        .dots {
            margin-left: 0;
        }
    }
}

@media (max-width: 600px) {
    .profile {
        flex-direction: row-reverse;
        margin-right: 2rem;
    }

    .balances {
        transform: unset !important;
        margin-right: 6rem;
    }

    .signup {
        margin-left: 0;
        margin-bottom: 0;
        margin-right: 1.5rem;
        margin-top: 0.75rem;
    }
}
</style>
