<script setup lang="ts">
import type { User } from "@/database/entities/user";
import { PhAt } from "@phosphor-icons/vue";
import { onMounted, ref } from "vue";
import { RouterLink } from "vue-router";
import { api } from "~/inertia/api";

const props = defineProps<{
	id: string;
}>();

const user = ref<User>();

onMounted(async () => {
	const userData = await api.user({ id: props.id }).get();
	if (userData.error) return;
	user.value = userData.data;
});
</script>

<template>
    <div v-if="user" class="profile">
        <ProfileIcon v-if="user.profile_picture" size="large" :img-src="user.profile_picture" />
        <ProfileIcon size="large" :id="props.id" />
        <div class="userDetails">
            <b>
                <RouterLink class="display_name" :to="'/@' + user.name" @click.stop
                    >{{ user.display_name ?? user.name }}
                </RouterLink>
            </b>
            <RouterLink class="username" :to="'/@' + user.name" @click.stop>
                <PhAt weight="bold" />{{ user.name }}
            </RouterLink>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.profile {
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;
    gap: 1em;
    padding: 0.5em 0.1em;
    border-radius: 99px;

    .username {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.5em;
        gap: 0.5em;
        color: var(--fg);
        background: var(--mint-900);
        padding: 0.1em 0.5em;
        border-radius: 99px;
        width: min-content;
    }
}
</style>
