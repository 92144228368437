import {
	CubeSignerClient,
	type Scope,
	type SessionData,
	envs,
} from "@cubist-labs/cubesigner-sdk";
import { BrowserStorageManager } from "@cubist-labs/cubesigner-sdk-browser-storage";
import { defineStore } from "pinia";
import { ref, watch } from "vue";
import { manager } from "~/cubist/browser-storage";
import { useAuthenticationStore } from "./authentication";
import { useOidcStore } from "./oidc";

const CUBE_SIGNER_SESSION_SCOPES: Array<Scope> = ["sign:*"];

const CUBIST_ENVIRONMENT =
	import.meta.env.VITE_CUBIST_ENVIRONMENT === "prod"
		? envs.prod
		: import.meta.env.VITE_CUBIST_ENVIRONMENT === "beta"
			? envs.beta
			: envs.gamma;

export const useCubeSignerStore = defineStore("cubist-session", () => {
	const initialized = ref(false);
	const authenticationStore = useAuthenticationStore();

	async function getCubeSignerClient(): Promise<CubeSignerClient> {
		await manager.token();
		return CubeSignerClient.create(manager);
	}

	manager
		.token()
		.then(() => {
			initialized.value = true;
		})
		.catch(() => {});

	manager.addEventListener("logout", () => {
		authenticationStore.logout();
	});

	const oidcStore = useOidcStore();

	// update the session when the id_token changes
	watch(
		() => oidcStore.id_token,
		(id_token) => {
			id_token && createNewSession(id_token);
		},
	);

	async function createNewSession(id_token: string) {
		manager.setSession(
			(
				await CubeSignerClient.createOidcSession(
					CUBIST_ENVIRONMENT,
					import.meta.env.VITE_CUBIST_ORGANIZATION_ID,
					id_token,
					CUBE_SIGNER_SESSION_SCOPES,
				)
			).data(),
		);
		initialized.value = true;
	}

	async function reset() {
		// there is no better way to clear the session
		manager.setSession({} as SessionData);
		initialized.value = false;
	}

	return {
		getCubeSignerClient,
		initialized,
		reset,
	};
});
