import { defineStore } from "pinia";

export const useBetaStore = defineStore("beta", {
	state: () => ({ betaKey: undefined as string | undefined }),
	actions: {
		setKey(key: string) {
			this.betaKey = key;
		},
	},
	persist: true,
});
