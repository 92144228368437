<script setup lang="ts">
import type { UserWithFollowData } from "@/database/entities/user";
import { trackUmamiEvent } from "@jaseeey/vue-umami-plugin";
import { PhCalendar, PhMinus, PhPlus } from "@phosphor-icons/vue";
import AvatarGroup from "primevue/avatargroup";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { api } from "~/inertia/api";
import { useAuthenticationStore } from "~/stores/authentication";

const route = useRoute();
const router = useRouter();
const authenticationStore = useAuthenticationStore();

const loading = ref(true);
const loadingMarkets = ref(true);
const id = ref<string>();
const user = ref<UserWithFollowData>();
const marketsOrdered = ref<any[]>([]);
const marketsCreated = ref<any[]>([]);
const tab = ref<number>(1);
const tabOptions = [
	{ name: "Created Predictions", context: { active: true }, value: 1 },
	{ name: "Order History", value: 2 },
];

async function follow() {
	if (!user.value || !id.value) {
		return;
	}
	if (user.value.you_follow) {
		await api.user({ id: id.value }).unfollow.post();
		trackUmamiEvent("unfollow-user", { source: "profile" });
	} else {
		await api.user({ id: id.value }).follow.post();
		trackUmamiEvent("follow-user", { source: "profile" });
	}
	// @ts-ignore
	user.value = (await api.user({ id: id.value }).get())?.data;
}

onMounted(async () => {
	const userIdReq = await api.user
		.id({ name: route.params.username.toString() })
		.get();
	if (!userIdReq.data?.id) {
		router.push("/404");
		return;
	}
	id.value = userIdReq.data.id.id as string;
	if (!id.value) {
		router.push("/404");
		return;
	}
	const userReq = await api.user({ id: id.value }).get();
	if (userReq.error) return;
	user.value = userReq.data;
	loading.value = false;
	const marketsCreatedResult = await api
		.user({ id: id.value as string })
		["created-markets"].get();
	if (marketsCreatedResult.error) return;
	marketsCreated.value = marketsCreatedResult.data;
	const marketsOrderedResult = await api
		.user({ id: id.value as string })
		["ordered-markets"].get();
	if (marketsOrderedResult.error) return;
	marketsOrdered.value = marketsOrderedResult.data;
	loadingMarkets.value = false;
});
</script>

<template>
    <main>
        <div class="card" v-if="user">
            <div class="headProfile">
                <ProfileIcon :id="id" />
                <div class="labels">
                    <h1>{{ user.display_name || user.name }}</h1>
                    <Username :username="user.name" :show-popover="false" />
                </div>
                <div class="followButtonWrapper" v-if="user.id.id !== authenticationStore.user?.id?.id">
                    <Button
                        v-if="!user.you_follow"
                        @click="follow"
                        v-auth
                        v-ripple
                        v-vibrate="10"
                        rounded
                        severity="contrast"
                        class="followButton"
                    >
                        <PhPlus weight="bold" /> {{ user.follows_you ? $t("follow.followBack") : $t("follow.follow") }}
                    </Button>
                    <Button
                        v-else
                        @click="follow"
                        rounded
                        v-ripple
                        v-vibrate="10"
                        severity="secondary"
                        class="followButton"
                    >
                        <PhMinus weight="bold" /> {{ $t("follow.unfollow") }}
                    </Button>
                    <div v-if="user.follows_you" class="detailChip">
                        {{ $t("follow.followsYou") }}
                    </div>
                </div>
            </div>
            <div class="userDetails">
                <div>
                    <RouterLink class="link" :to="'/@' + user.name + '/followers'">
                        {{ $t("follow.followers", { count: user.followers }) }}
                    </RouterLink>
                    <span style="color: var(--mint-700); margin: 0 0.5em">•</span>
                    <RouterLink class="link" :to="'/@' + user.name + '/following'">
                        {{ $t("follow.following", { count: user.following }) }}
                    </RouterLink>
                </div>
                <div
                    style="display: flex; flex-direction: row; gap: 0.5em"
                    v-if="authenticationStore.isAuthenticated() && user.followers_you_know?.length > 0"
                >
                    <p>{{ $t("follow.followersYouKnow") }}</p>
                    <AvatarGroup>
                        <ProfileIcon v-for="follower in user.followers_you_know" :id="follower" size="small" />
                    </AvatarGroup>
                </div>
                <div class="detailChip" :style="{ marginTop: user.followers_you_know?.length === 0 ? '0.5em' : '0' }">
                    <PhCalendar weight="bold" />
                    {{ $t("joined") }}
                    <time :datetime="user.created_at">{{ $d(user.created_at) }}</time>
                </div>
            </div>
        </div>
        <SelectButton
            style="margin: 2em 0"
            v-model="tab"
            :allowEmpty="false"
            :options="tabOptions"
            optionLabel="name"
            optionValue="value"
            aria-labelledby="basic"
        />

        <MarketSkeletons v-if="loadingMarkets" />
        <h3 v-else-if="(tab === 1 && marketsCreated.length === 0) || (tab === 2 && marketsOrdered.length === 0)">
            {{ $t("empty") }}
        </h3>
        <div v-else>
            <Market v-if="tab === 1" v-for="id in marketsCreated" :id="id.id" />
            <Market v-else v-for="id in marketsOrdered" :id="id.id" />
        </div>
    </main>
</template>

<style lang="scss" scoped>
.headProfile {
    display: flex;
    flex-direction: row;
    margin: 2em 0;
    align-items: center;

    .labels {
        h1 {
            margin: 0;
        }

        display: flex;
        flex-direction: column;
        gap: 0.5em;
        margin-left: 1em;
    }

    .followButtonWrapper {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        align-items: center;
        justify-content: center;
        margin-left: 1em;

        .followButton {
            height: fit-content;
        }
    }
}

.userDetails {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
    gap: 0.5em;
}

.detailChip {
    display: flex;
    align-items: center;
    gap: 0.5em;
    background: var(--darkMint-900);
    padding: 0.2em 0.5em;
    border-radius: 6px;
    width: fit-content;
}
</style>
