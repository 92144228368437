<script setup lang="ts">
import { trackUmamiEvent } from "@jaseeey/vue-umami-plugin";
import NumberFlow from "@number-flow/vue";
import type { Component } from "vue";
// import { useSound } from '@vueuse/sound'

const props = withDefaults(
	defineProps<{
		state: boolean;
		count: number;
		icon: Component;
		label: string;
		action?: () => void;
		disabled?: boolean;
		ripple?: boolean;
		allowUnauthenticated?: boolean;
	}>(),
	{
		state: false,
		count: 0,
		icon: undefined,
		label: "",
		action: () => {},
		disabled: false,
		ripple: true,
		allowUnauthenticated: false,
	},
);

const click = () => {
	// useSound('click');
	trackUmamiEvent("interact-button", { buttonName: props.label });
	props.action();
};
</script>

<template>
    <button
        v-auth
        v-vibrate="1"
        :class="props.state ? 'interaction enabled' : 'interaction'"
        v-ripple="props.ripple"
        @click="click"
        @click.stop
        type="button"
        :disabled="props.disabled"
        :aria-label="props.label"
    >
        <component :is="props.icon" :weight="props.state ? 'fill' : 'bold'" />
        <NumberFlow :value="props.count" continuous :format="{ notation: 'compact', compactDisplay: 'short' }" />
    </button>
</template>

<style lang="scss" scoped>
.interaction {
    padding: 0.65em 0.8em;
    display: inline-flex;
    align-items: center;
    margin-right: 0.5em;
    font-size: 1em;
    color: var(--fg);
    background-color: var(--darkGray-500);
    border: 1px solid var(--darkGray-300) !important;
    border-radius: 10px;
    font-family: var(--fonts);
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;

    svg {
        margin-right: 0.5em;
        transform: scale(1.2);
    }

    &:hover {
        background-color: var(--darkGray-400);
    }

    &.enabled {
        background-color: var(--mint-900);
        border-color: var(--mint-700) !important;
    }

    &[disabled] {
        background-color: var(--darkGray-600);
        color: var(--lightGray-900);
    }
}
</style>
