import { createRouter, createWebHistory } from "vue-router";
import { useAuthenticationStore } from "~/stores/authentication";
import { useBetaStore } from "~/stores/beta";
import CreateMarket from "~/views/CreateMarket.vue";
import Explore from "~/views/Explore.vue";
import Follows from "~/views/Follows.vue";
import Friends from "~/views/Friends.vue";
import Home from "~/views/Home.vue";
import Market from "~/views/Market.vue";
import Notifications from "~/views/Notifications.vue";
import Profile from "~/views/Profile.vue";
import Settings from "~/views/Settings.vue";
// TODO: remove after closed beta
import Beta from "~/views/authentication/Beta.vue";

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: "/",
			name: "home",
			component: Home,
		},
		// TODO: remove after closed beta
		{
			path: "/beta",
			name: "beta",
			component: Beta,
		},
		{
			path: "/callback",
			name: "callback",
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import("~/views/Callback.vue"),
		},
		{
			path: "/:catchAll(.*)",
			name: "404",
			component: () => import("~/views/404.vue"),
		},
		{
			path: "/sign-up",
			name: "sign-up",
			component: () => import("~/views/authentication/SignUp.vue"),
		},
		{
			path: "/explore",
			name: "explore",
			component: Explore,
		},
		{
			path: "/notifications",
			name: "notifications",
			component: Notifications,
			meta: { requiresAuth: true },
		},
		{
			path: "/friends",
			name: "friends",
			component: Friends,
			meta: { requiresAuth: true },
		},
		// {
		// 	path: "/pro",
		// 	name: "pro",
		// 	component: () => import("~/views/Pro.vue"),
		// },
		{
			path: "/@:username",
			name: "user",
			component: Profile,
		},
		{
			path: "/@:username/following",
			name: "following",
			component: Follows,
		},
		{
			path: "/@:username/followers",
			name: "followers",
			component: Follows,
			meta: { followers: true },
		},
		{
			path: "/p/:id",
			name: "prediction",
			component: Market,
		},
		{
			path: "/settings",
			name: "settings",
			component: Settings,
			meta: { requiresAuth: true },
		},
		{
			path: "/create",
			name: "create",
			component: CreateMarket,
			meta: { requiresAuth: true },
		},
		{
			path: "/docs/:doc",
			name: "docs",
			component: () => import("~/views/Docs.vue"),
		},
	],
});

router.beforeEach((to) => {
	const authenticationStore = useAuthenticationStore();
	const betaStore = useBetaStore();
	// TODO: remove after closed beta
	if (!betaStore.betaKey && to.name !== "beta" && to.name !== "docs") {
		return "/beta";
	}
	if (to.meta.requiresAuth && !authenticationStore.isAuthenticated()) {
		return "/";
	}
});

export default router;
