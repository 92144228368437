import { computed, reactive } from "vue";

export const useViewportStore = () => {
	const MOBILE_MAX_WIDTH = 600;

	const state = reactive({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const isMobile = computed(() => state.width <= MOBILE_MAX_WIDTH);
	const isDesktop = computed(() => state.width > MOBILE_MAX_WIDTH);

	window.addEventListener("resize", () => {
		state.width = window.innerWidth;
		state.height = window.innerHeight;
	});

	return {
		state,
		isMobile,
		isDesktop,
	};
};
