import type { User } from "@/database/entities/user";
import { api } from "~/inertia/api";

export async function addressToUser(
	address: string,
): Promise<User | { name: string; id: string }> {
	const user = await api.user.address({ address: address }).get();
	if (user.data) {
		return user.data;
	}
	return {
		id: address,
		name: `${address.substring(0, 5)}...${address.slice(-4)}`,
	};
}
