import type {
	CubeSignerResponse,
	EvmSignRequest,
	EvmSignResponse,
	Key,
	MfaReceipt,
} from "@cubist-labs/cubesigner-sdk";
import { defineStore } from "pinia";
import {
	http,
	type Address,
	type WalletClient,
	createPublicClient,
	createWalletClient,
	publicActions,
} from "viem";
import { avalanche, avalancheFuji } from "viem/chains";
import { markRaw, ref, watch } from "vue";
import { getWalletBalances } from "~/utils/onchainActions";
import { useCubeSignerStore } from "./cube-signer-store";

// TODO: this should probably just depend on one global environment, just like everything else
const AVALANCHE_ENVIRONMENT =
	import.meta.env.VITE_CUBIST_ENVIRONMENT === "prod" ? "mainnet" : "testnet";

const chainToUse =
	AVALANCHE_ENVIRONMENT === "mainnet" ? avalanche : avalancheFuji;

export const publicAvaxClient = createPublicClient({
	chain: chainToUse,
	transport: http(
		`https://ava-${AVALANCHE_ENVIRONMENT}.blastapi.io/${import.meta.env.VITE_RPC_ID}/ext/bc/C/rpc`,
	),
});

const BALANCE_UPDATE_INTERVAL = 15000;

export const useWalletStore = defineStore(
	"wallet",
	() => {
		const client = ref<WalletClient | undefined>(undefined);
		const sessionKey = ref<Key | undefined>(undefined);
		const usdcBalance = ref<number | undefined>(undefined);
		const walletAddress = ref<Address | undefined>(undefined);
		const balanceIsLoading = ref<boolean>(false);

		let balanceUpdateInterval: Timer | undefined = undefined;

		const cubeSignerStore = useCubeSignerStore();

		watch(
			() => cubeSignerStore.initialized,
			async (newValue) => {
				if (newValue) {
					await initializeWallet();
					await updateBalance();
					startBalanceUpdateInterval();
				}
			},
		);

		async function initializeWallet() {
			const cubeSignerClient =
				await cubeSignerStore.getCubeSignerClient();
			if (!cubeSignerClient) {
				return;
			}
			const sessionKeys = await cubeSignerClient.sessionKeys();
			sessionKey.value = markRaw(sessionKeys[0]);
			walletAddress.value = sessionKeys[0].materialId as Address;
			client.value = createWalletClient({
				account: walletAddress.value,
				chain: chainToUse,
				transport: http(
					`https://ava-${AVALANCHE_ENVIRONMENT}.blastapi.io/${import.meta.env.VITE_RPC_ID}/ext/bc/C/rpc`,
				),
			}).extend(publicActions);
		}

		async function updateBalance() {
			balanceIsLoading.value = true;
			if (!walletAddress.value) {
				return;
			}
			const balances = await getWalletBalances(walletAddress.value);
			usdcBalance.value = Number(balances.usdcBalance);
			balanceIsLoading.value = false;
		}

		function startBalanceUpdateInterval() {
			if (balanceUpdateInterval) {
				clearInterval(balanceUpdateInterval);
			}
			balanceUpdateInterval = setInterval(() => {
				updateBalance();
			}, BALANCE_UPDATE_INTERVAL);
		}

		function reset() {
			walletAddress.value = undefined;
			usdcBalance.value = undefined;
			balanceIsLoading.value = true;
		}

		async function signTransaction(
			req: EvmSignRequest,
			mfaReceipt?: MfaReceipt,
		): Promise<CubeSignerResponse<EvmSignResponse>> {
			if (!sessionKey.value) {
				throw new Error("No session key available");
			}
			return sessionKey.value.signEvm(req, mfaReceipt);
		}

		return {
			walletAddress,
			usdcBalance,
			balanceIsLoading,
			updateBalance,
			reset,
			signTransaction,
		};
	},
	{
		persist: {
			pick: ["walletAddress", "balance"],
			afterHydrate: (state) => {
				// This makes sure the latest known balance is shown immediately.
				// It will be updated periodically after this.
				if (state.store.$state.balance) {
					state.store.$state.balanceIsLoading = false;
				}
			},
		},
	},
);
