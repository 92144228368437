<script setup lang="ts">
import { gsap } from "gsap";
import { onMounted } from "vue";

defineProps<{
	text: string;
	disabled: boolean;
	fn: () => void;
}>();

onMounted(() => {
	for (const button of document.querySelectorAll(".generate-button")) {
		const timeline = gsap.timeline({ paused: true });

		const finalTimeline = gsap.to(timeline, {
			duration: 10,
			repeat: -1,
			time: timeline.duration(),
			paused: true,
		});

		const stars = gsap.to(button, {
			repeat: -1,
			repeatDelay: 0.75,
			paused: true,
			keyframes: [
				{
					"--generate-button-star-2-scale": ".5",
					"--generate-button-star-2-opacity": ".25",
					"--generate-button-star-3-scale": "1.25",
					"--generate-button-star-3-opacity": "1",
					duration: 0.3,
				},
				{
					"--generate-button-star-1-scale": "1.5",
					"--generate-button-star-1-opacity": ".5",
					"--generate-button-star-2-scale": ".5",
					"--generate-button-star-3-scale": "1",
					"--generate-button-star-3-opacity": ".5",
					duration: 0.3,
				},
				{
					"--generate-button-star-1-scale": "1",
					"--generate-button-star-1-opacity": ".25",
					"--generate-button-star-2-scale": "1.15",
					"--generate-button-star-2-opacity": "1",
					duration: 0.3,
				},
				{
					"--generate-button-star-2-scale": "1",
					duration: 0.35,
				},
			],
		});

		button.addEventListener("pointerenter", () => {
			gsap.to(button, {
				"--generate-button-dots-opacity": ".5",
				duration: 0.25,
				onStart: () => {
					finalTimeline.restart().play();
					setTimeout(() => stars.restart().play(), 500);
				},
			});
		});

		button.addEventListener("pointerleave", () => {
			gsap.to(button, {
				"--generate-button-dots-opacity": "0",
				"--generate-button-star-1-opacity": ".25",
				"--generate-button-star-1-scale": "1",
				"--generate-button-star-2-opacity": "1",
				"--generate-button-star-2-scale": "1",
				"--generate-button-star-3-opacity": ".5",
				"--generate-button-star-3-scale": "1",
				duration: 0.15,
				onComplete: () => {
					finalTimeline.pause();
					stars.pause();
				},
			});
		});
	}
});
</script>

<template>
    <div class="buttonHolder">
        <button ref="generateButton" class="generate-button" @click.stop="fn" :disabled="disabled" v-vibrate="100">
            <svg class="icon" viewBox="0 0 24 26">
                <path
                    ref="sparkle1"
                    d="M5.16515 2.62145L5.8075 0.999247C5.83876 0.919722 5.9154 0.866699 6.00112 0.866699C6.08683 0.866699 6.16347 0.919722 6.19473 0.999247L6.83708 2.62145L8.44145 3.27094C8.5201 3.30254 8.57254 3.38003 8.57254 3.4667C8.57254 3.55337 8.5201 3.63085 8.44145 3.66246L6.83708 4.31195L6.19473 5.93415C6.16347 6.0147 6.08683 6.0667 6.00112 6.0667C5.9154 6.0667 5.83876 6.0147 5.8075 5.93415L5.16515 4.31195L3.56078 3.66246C3.48112 3.63085 3.42969 3.55337 3.42969 3.4667C3.42969 3.38003 3.48112 3.30254 3.56078 3.27094L5.16515 2.62145Z"
                ></path>
                <path
                    ref="sparkle2"
                    d="M11.2362 9.43967C11.5502 9.30067 11.8015 9.05025 11.9405 8.73617L13.5494 5.11725C13.7169 4.74204 14.0887 4.5 14.5 4.5C14.9112 4.5 15.2839 4.74204 15.4506 5.11725L17.0603 8.73617C17.1985 9.05025 17.4497 9.3015 17.7638 9.43967L21.3827 11.0494C21.7579 11.2161 22 11.5887 22 12C22 12.4112 21.7579 12.7831 21.3827 12.9506L17.7638 14.5595C17.4497 14.6985 17.1993 14.9497 17.0603 15.2638L15.4506 18.8827C15.2839 19.2579 14.9112 19.5 14.5 19.5C14.0887 19.5 13.7169 19.2579 13.5494 18.8827L11.9405 15.2638C11.8015 14.9497 11.5502 14.6985 11.2362 14.5595L7.61725 12.9506C7.24204 12.7831 7 12.4112 7 12C7 11.5887 7.24204 11.2161 7.61725 11.0494L11.2362 9.43967Z"
                ></path>
                <path
                    ref="sparkle3"
                    d="M4.60728 19.392L5.67703 16.6875C5.72997 16.5541 5.85854 16.4666 6.00056 16.4666C6.14258 16.4666 6.27031 16.5541 6.32325 16.6875L7.39299 19.392L10.0678 20.4736C10.1997 20.5271 10.2863 20.6563 10.2863 20.7999C10.2863 20.9435 10.1997 21.0735 10.0678 21.1271L7.39299 22.2087L6.32325 24.9123C6.27031 25.0457 6.14258 25.1332 6.00056 25.1332C5.85854 25.1332 5.72997 25.0457 5.67703 24.9123L4.60728 22.2087L1.93333 21.1271C1.8014 21.0735 1.71484 20.9435 1.71484 20.7999C1.71484 20.6563 1.8014 20.5271 1.93333 20.4736L4.60728 19.392Z"
                ></path>
            </svg>
            <span>{{ text }}</span>
        </button>
    </div>
</template>

<style lang="scss" scoped>
.buttonHolder {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 2em 0em 2.5em 0;
}

.buttonHolder .generate-button {
    --generate-button-star-1-opacity: 0.25;
    --generate-button-star-1-scale: 1;
    --generate-button-star-2-opacity: 1;
    --generate-button-star-2-scale: 1;
    --generate-button-star-3-opacity: 0.5;
    --generate-button-star-3-scale: 1;
    --generate-button-dots-opacity: 0;
    appearance: none;
    outline: none;
    border: none;
    padding: 16px 24px 16px 20px;
    border-radius: 29px;
    margin: 0;
    background-color: var(--mint-900);
    color: var(--fg);
    min-width: 192px;
    display: flex;
    position: relative;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    z-index: 1;
    transform: scale(var(--generate-button-scale, 1)) translateZ(0);
    box-shadow:
        0px 0px 120px var(--generate-button-shadow-wide, transparent),
        0px 4px 12px rgba(0, 0, 0, 0.05),
        0px 1px 2px rgba(0, 0, 0, 0.1),
        inset 0px 1px 1px var(--generate-button-shadow-inset, rgba(255, 255, 255, 0.04)),
        0 0 0 var(--generate-button-shadow-outline, 0px) var(--darkMint-700);
    transition:
        transform 0.3s,
        background-color 0.3s,
        box-shadow 0.3s,
        color 0.3s;
}

.buttonHolder .generate-button :before {
    content: "";
    display: block;
    position: absolute;
    right: 20%;
    height: 20px;
    left: 20%;
    bottom: -10px;
    background: var(--mint-300);
    filter: blur(12.5px);
    z-index: 2;
    clip-path: inset(-200% -30% 10px -30% round 29px);
    opacity: 0;
    transition: opacity 0.4s;
    transform: translateZ(0);
}

.buttonHolder .generate-button span {
    position: relative;
    z-index: 1;
    font-family: var(--fonts);
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.005em;
    display: block;
}

.buttonHolder .generate-button span :before {
    content: "";
    background-image: linear-gradient(to right, transparent, #1d1d1d);
    position: absolute;
    inset: 0;
    z-index: 1;
    pointer-events: none;
    opacity: 0.4;
    transition: opacity 0.4s;
}

.buttonHolder .generate-button .stroke {
    mix-blend-mode: hard-light;
}

.buttonHolder .generate-button .stroke svg {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    fill: none;
    stroke-width: 0.75px;
    stroke: var(--darkMint-300);
    stroke-dasharray: 1.5 14;
    stroke-dashoffset: 22;
    opacity: 0;
}

.buttonHolder .generate-button .stroke svg:nth-child(2) {
    stroke-width: 1px;
    stroke-opacity: 0.5;
    filter: blur(3px);
}

.buttonHolder .generate-button svg {
    display: block;
    overflow: visible;
    pointer-events: none;
}

.buttonHolder .icon {
    width: 24px;
    height: 26px;
    margin-right: 12px;
    fill: var(--darkMint-300);
}

.buttonHolder svg.icon path:nth-child(1) {
    opacity: var(--generate-button-star-1-opacity);
    transform: scale(var(--generate-button-star-1-scale)) translateZ(0);
    transform-origin: 25% 14.58%;
}

.buttonHolder svg.icon path:nth-child(2) {
    opacity: var(--generate-button-star-2-opacity);
    transform: scale(var(--generate-button-star-2-scale)) translateZ(0);
    transform-origin: 60.42% 50%;
}

.buttonHolder svg.icon path:nth-child(3) {
    opacity: var(--generate-button-star-3-opacity);
    transform: scale(var(--generate-button-star-3-scale)) translateZ(0);
    transform-origin: 25% 85.42%;
}

.buttonHolder :disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.buttonHolder :hover {
    --generate-button-scale: 1.1;
    --generate-button-shadow-wide: var(--darkGray-400);
    --generate-button-shadow-inset: rgba(255, 255, 255, 0.35);
    --generate-button-shadow-outline: 3px;
    color: var(--lightGray-50);
    // background-color: var(--mint-800);

    &:disabled {
        --generate-button-scale: 1;
    }
}

.buttonHolder :hover .stroke svg {
    animation: stroke 2s linear infinite;
}

.buttonHolder :hover :before {
    opacity: 1;
}

.buttonHolder :hover span:before {
    opacity: 0;
}

.buttonHolder :hover :active {
    --generate-button-scale: 1.05;
}

@-moz-keyframes stroke {
    0% {
        opacity: 0;
    }

    25%,
    75% {
        opacity: 1;
    }

    95%,
    100% {
        stroke-dashoffset: 6;
        opacity: 0;
    }
}

@-webkit-keyframes stroke {
    0% {
        opacity: 0;
    }

    25%,
    75% {
        opacity: 1;
    }

    95%,
    100% {
        stroke-dashoffset: 6;
        opacity: 0;
    }
}

@-o-keyframes stroke {
    0% {
        opacity: 0;
    }

    25%,
    75% {
        opacity: 1;
    }

    95%,
    100% {
        stroke-dashoffset: 6;
        opacity: 0;
    }
}

@keyframes stroke {
    0% {
        opacity: 0;
    }

    25%,
    75% {
        opacity: 1;
    }

    95%,
    100% {
        stroke-dashoffset: 6;
        opacity: 0;
    }
}
</style>
