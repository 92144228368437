<script setup lang="ts">
import { api } from "~/inertia/api";
import { useWalletStore } from "~/stores/wallet";

type OnrampEvent = {
	eventName: "open" | "exit" | "success";
	error?: {
		errorType: string;
		code?: string;
		message?: string;
	};
};

const POPUP_DIMENSIONS = {
	width: 460,
	height: 720,
};

function setupEventListener(
	origin: string,
	callback: (event: OnrampEvent) => void,
): () => void {
	const handler = (event: MessageEvent) => {
		if (event.origin !== origin) return;

		try {
			const data = event.data as OnrampEvent;
			callback(data);
		} catch (error) {
			console.error("Error processing onramp event:", error);
		}
	};

	window.addEventListener("message", handler);
	return () => window.removeEventListener("message", handler);
}

const emit = defineEmits<{
	(e: "success"): void;
	(e: "exit", error?: Error): void;
}>();

async function openPopup() {
	const walletStore = useWalletStore();

	if (!walletStore.walletAddress) {
		return;
	}

	const onrampResponse = await api.coinbase.onramp.get({
		query: {
			walletAddress: walletStore.walletAddress,
		},
	});

	if (!onrampResponse.data) {
		return;
	}

	const url = onrampResponse.data;

	const { width, height } = POPUP_DIMENSIONS;
	const left = (window.screen.width - width) / 2;
	const top = (window.screen.height - height) / 2;

	const popup = window.open(
		url,
		"CoinbaseFund",
		`width=${width},height=${height},left=${left},top=${top}`,
	);

	if (!popup) {
		console.error("Failed to open popup - it may have been blocked");
		return;
	}

	const cleanup = setupEventListener(url, (event) => {
		if (event.eventName === "success") {
			emit("success");
		} else if (event.eventName === "exit") {
			emit("exit", event.error && new Error(event.error.message));
		}
	});

	popup.addEventListener("close", cleanup);
}
</script>

<template>
    <Button @click="openPopup" role="button" :label="$t('balance')">
        <svg fill="none" height="16px" xmlns="http://www.w3.org/2000/svg" viewBox="0.05 -0.03 31.97 32.05">
            <path d="M16 24a8 8 0 1 1 8-9.3h8a16 16 0 1 0 0 2.6h-8a8 8 0 0 1-8 6.7Z" fill="currentColor"></path>
        </svg>
        <span> {{ $t("coinbase.title") }} </span>
    </Button>
</template>
