<template>
    <section id="logo">
        <router-link to="/">
            <img class="logo" src="/transparent.svg" alt="Inertia" />
            <div class="wordmark">
                <svg width="105" height="22.5" viewBox="0 0 420 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10.88 89.7V33.97H0V22.31H33.64V89.7H10.88ZM10.88 11.66V0H33.64V11.66H10.88Z"
                        fill="currentColor"
                    />
                    <path
                        d="M44.85 89.6999V22.4199H101.03V33.5199H112.13V89.6999H89.37V34.0799H78.72V45.1799H67.62V89.6999H44.86H44.85Z"
                        fill="currentColor"
                    />
                    <path
                        d="M134.44 89.6999V78.5999H123.34V33.5199H134.44V22.4199H179.52V33.5199H190.62V67.4899H146.11V78.0299H179.52V89.6899H134.44V89.6999ZM146.11 34.0899V55.8399H167.86V34.0899H146.11Z"
                        fill="currentColor"
                    />
                    <path
                        d="M201.83 89.6999V22.4199H269.11V34.0799H235.7V45.1799H224.6V89.6999H201.84H201.83Z"
                        fill="currentColor"
                    />
                    <path
                        d="M273.37 89.7V78.6H262.27V33.97H251.17V22.31H262.27V11.21H285.03V22.31H296.13V33.97H285.03V78.0399H307.23V89.7H273.37Z"
                        fill="currentColor"
                    />
                    <path
                        d="M318.11 89.7V33.97H307.23V22.31H340.87V89.7H318.11ZM318.11 11.66V0H340.87V11.66H318.11Z"
                        fill="currentColor"
                    />
                    <path
                        d="M352.09 78.5999V55.8399H363.19V44.6299H396.6V34.0899H363.19V22.4299H408.27V33.5299H419.37V78.6099H408.27V89.7099H363.19V78.6099H352.09V78.5999ZM374.85 56.2899V78.0399H396.6V56.2899H374.85Z"
                        fill="currentColor"
                    />
                </svg>
            </div>
            <!-- <h1>Inertia</h1> -->
        </router-link>
    </section>
</template>

<style lang="scss" scoped>
#logo a {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.7em;
    padding: 0 0.5em;
    text-decoration: none;

    .logo {
        height: 1.7em;
        background-color: var(--mint-500);
        border-radius: 99px;
        transition: background-color 0.2s ease;

        &:hover {
            background-color: var(--mint-300);
        }
    }

    .wordmark {
        margin: 0;
        color: var(--mint-50);
        transition: color 0.2s ease;

        &:hover {
            color: var(--mint-200);
        }
    }

    @media (max-width: 1050px) {
        margin-top: 1rem;

        .wordmark {
            display: none;
        }
    }

    @media (max-width: 600px) {
        margin-left: 1rem;

        .wordmark {
            display: initial;
        }
    }
}
</style>
