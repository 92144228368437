<script setup lang="ts">
import { onMounted, ref, useTemplateRef, watch, watchEffect } from "vue";

const props = defineProps<{
	percentage: number;
	label: string;
	color: string;
	highlighted: boolean;
}>();

const marketButton = useTemplateRef("marketButton");
const fontColor = ref<string>();
const itemColorStart = ref<string>();
const itemColorEnd = ref<string>();
const highlightColorStart = ref<string>();
const highlightColorEnd = ref<string>();
const backgroundColor = ref<string>();
const highlightBackgroundColor = ref<string>();
const buttonWidth = ref<string>();

function updateColors() {
	fontColor.value =
		props.color === "good" ? "var(--mint-100)" : "var(--cinnamon-100)";
	itemColorStart.value =
		props.color === "good" ? "var(--mint-950)" : "var(--cinnamon-950)";
	itemColorEnd.value =
		props.color === "good" ? "var(--mint-800)" : "var(--cinnamon-800)";
	backgroundColor.value =
		props.color === "good" ? "var(--mint-950)" : "var(--cinnamon-950)";
	highlightColorStart.value =
		props.color === "good" ? "var(--mint-900)" : "var(--cinnamon-900)";
	highlightColorEnd.value =
		props.color === "good" ? "var(--mint-600)" : "var(--cinnamon-600)";
	highlightBackgroundColor.value =
		props.color === "good" ? "var(--mint-900)" : "var(--cinnamon-900)";
	buttonWidth.value = `${props.percentage.toString()}%`;
}

onMounted(() => {
	updateColors();
	watchEffect(() => {
		if (!marketButton.value) return;
		if (props.highlighted) {
			marketButton.value.classList.add("highlighted");
		} else {
			marketButton.value.classList.remove("highlighted");
		}
	});
});

watch(props, () => {
	updateColors();
});
</script>

<template>
    <button ref="marketButton" class="marketOption" type="button">{{ label }} - {{ percentage }}%</button>
</template>

<style lang="scss" scoped>
.marketOption {
    padding: 1em;
    margin-top: 0.5em;
    text-align: left;
    border: none;
    cursor: pointer;
    color: v-bind(fontColor);
    border-radius: 10px;
    transition: all 0.15s ease-in-out;
    font-weight: 700;
    background-color: v-bind(backgroundColor);
    background: linear-gradient(
        to right,
        v-bind(itemColorStart) 0%,
        v-bind(itemColorEnd) v-bind(buttonWidth),
        v-bind(backgroundColor) v-bind(buttonWidth) 100%
    );
    font-family: var(--fonts);

    &:hover {
        background-color: v-bind(highlightBackgroundColor) !important;
    }

    &.highlighted {
        background: linear-gradient(
            to right,
            v-bind(highlightColorStart) 0%,
            v-bind(highlightColorEnd) v-bind(buttonWidth),
            v-bind(highlightBackgroundColor) v-bind(buttonWidth) 100%
        );
        box-shadow: 0 0 10px v-bind(itemColorStart);
        transition:
            transform 0.2s,
            background-color 0.2s,
            box-shadow 0.2s,
            color 0.2s;
    }
}
</style>
