<script setup lang="ts">
import {
	PhDevToLogo,
	PhGithubLogo,
	PhTelegramLogo,
	PhXLogo,
} from "@phosphor-icons/vue";

const currentYear = new Date().getFullYear();
</script>

<template>
    <footer>
        <nav id="socials">
            <ul>
                <li>
                    <a href="https://x.com/@inertia_social">
                        <PhXLogo />
                    </a>
                </li>
                <!-- <li>
                    <a href="https://github.com/InertiaSocial">
                        <PhGithubLogo />
                    </a>
                </li> -->
                <li>
                    <a href="https://t.me/inertia_social">
                        <PhTelegramLogo />
                    </a>
                </li>
                <!-- <li>
                    <a href="https://dev.to/inertia">
                        <PhDevToLogo />
                    </a>
                </li> -->
            </ul>
        </nav>
        <nav id="legal">
            <ul>
                <li>
                    <RouterLink class="link" to="/docs/tos">{{ $t("terms") }}</RouterLink>
                </li>
                <span>&#8226;</span>
                <li>
                    <RouterLink class="link" to="/docs/privacy">{{ $t("privacy") }}</RouterLink>
                </li>
                <!-- <li>
                    <a href="/knowledgebase">{{ $t("knowledgebase") }}</a>
                </li> -->
            </ul>
        </nav>
        <p id="copyright">{{ $t("copyright", { year: currentYear !== 2024 ? `- ${currentYear}` : "" }) }}</p>
    </footer>
</template>

<style lang="scss" scoped>
footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: end;
    gap: 1em 4em;
    padding: 1em 2em;

    #legal ul {
        list-style: none;
        padding: 0;
        display: flex;
        gap: 0.5em;

        span {
            color: var(--darkMint-700);
        }
    }

    #socials ul {
        list-style: none;
        padding: 0;
        display: flex;
        gap: 1.5em;

        svg {
            width: 1.5em;
            height: 1.5em;
        }

        li {
            a {
                display: flex;
                color: var(--mint-700);
                text-decoration: none;
                transition: color 0.4s ease-in-out;

                svg {
                    filter: none;
                    transition: filter 0.4s ease-in-out;
                }

                &:hover {
                    color: var(--mint-300);

                    svg {
                        filter: drop-shadow(0 0 1em var(--mint-700)) drop-shadow(0 0 2em var(--mint-700));
                    }
                }
            }
        }
    }

    p#copyright {
        color: var(--mint-700);
    }
}
</style>
