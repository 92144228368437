<script setup lang="ts">
import { PhAt } from "@phosphor-icons/vue";

const props = defineProps({
	username: String,
	showPopover: Boolean,
});
</script>

<template>
    <div class="username"><PhAt weight="bold" />{{ props.username }}</div>
</template>

<style lang="scss" scoped>
.username {
    display: flex;
    align-items: center;
    gap: 0.5em;
    color: var(--fg);
    background: var(--mint-900);
    padding: 0.1em 0.4em;
    border-radius: 99px;
    width: min-content;
}
</style>
