<script setup lang="ts">
import { trackUmamiEvent } from "@jaseeey/vue-umami-plugin";
import Message from "primevue/message";
import Password from "primevue/password";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { vibrate } from "~/directives/vibrate";
import { api } from "~/inertia/api";
import { useBetaStore } from "~/stores/beta";

const betaStore = useBetaStore();
const router = useRouter();
const code = ref<string>("");
const wrong = ref<boolean>(false);

async function sendCode() {
	const result = await api.auth.beta.post({
		betaKey: code.value,
	});
	if (result.data?.ok) {
		betaStore.setKey(result.data.key);
		router.push("/");
	} else {
		wrong.value = true;
		vibrate(200);
		setTimeout(() => {
			wrong.value = false;
		}, 1500);
	}
	trackUmamiEvent("submit-beta-code", { correct: !wrong.value });
}
</script>

<template>
    <main>
        <div class="cover">
            <h1>Welcome to the Inertia Social beta!</h1>
            <Message severity="warn" style="max-width: 80vw">
                Warning: this is a <strong>closed beta</strong>, expect bugs and incomplete functionality.
            </Message>
            <p>Please enter the beta password:</p>
            <Password @keyup.enter="sendCode" v-model="code" size="large" :feedback="false" :invalid="wrong"
                :class="{ shake: wrong }" />
            <Button @click="sendCode" v-vibrate="1">Submit</Button>
        </div>
    </main>
</template>

<style lang="scss" scoped>
@media (max-width: 768px) {
    h1 {
        font-size: 18pt;
        font-size: 18pt;
        text-align: center;
        line-height: 1.5em;
    }
}

.shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
}

@keyframes shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}
</style>
