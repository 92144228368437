<script setup lang="ts">
import { PhAt, PhChatCircleText, PhHeart } from "@phosphor-icons/vue";
import { UseTimeAgo } from "@vueuse/components";
import { onMounted, ref } from "vue";
import { api } from "~/inertia/api";
import { useAuthenticationStore } from "~/stores/authentication";

const props = defineProps({
	id: String,
	author: String,
	authorName: String,
	authorUsername: String,
	text: String,
	createdAt: String,
	reply: Boolean,
	lastReply: Boolean,
	hasReplies: Boolean,
	replyCount: Number,
	likeCount: Number,
});

const authenticationStore = useAuthenticationStore();

const liked = ref<boolean>(false);
const likes = ref<number>(props.likeCount ?? 0);
const replies = ref<number>(props.replyCount ?? 0);
const compose = ref<boolean>(false);

const emit = defineEmits(["newReply"]);

async function performLike() {
	if (!props.id) return;
	if (liked.value) {
		api.comment({
			id: props.id,
		})
			.unlike.post()
			.then((result) => {
				if (result.data?.ok) {
					liked.value = false;
					likes.value--;
				}
			});
	} else {
		api.comment({
			id: props.id,
		})
			.like.post()
			.then((result) => {
				if (result.data?.ok) {
					likes.value++;
					liked.value = true;
				}
			});
	}
}

function showCompose() {
	if (authenticationStore.isAuthenticated()) {
		compose.value = !compose.value;
	}
}

function addReply(ev: {
	comment: Comment;
	parentId: string;
	parentType: "market" | "comment";
}) {
	compose.value = false;
	replies.value++;
	emit("newReply", ev);
}

onMounted(async () => {
	if (!props.id) return;
	likes.value = props.likeCount ?? 0;
	const commentReq = await api.comment({ id: props.id }).get();
	if (authenticationStore.isAuthenticated()) {
		const likedData = await api.comment({ id: props.id }).liked.get();
		if (likedData.error) return;
		liked.value = likedData.data.likes;
	}
	if (!commentReq.data) return;
	likes.value = commentReq.data.likes.length ?? 0;
});
</script>

<template>
    <div :class="props.reply ? (props.lastReply ? 'comment reply last' : 'comment reply') : 'comment'">
        <div class="topLine">
            <div class="profileLine">
                <ProfileIcon class="pfp" :id="props.author" size="normal" />
                <b>{{ props.authorName }}</b>
                <RouterLink class="username usernameMargin" :to="'/@' + props.authorUsername" @click.stop>
                    <PhAt weight="bold" />{{ props.authorUsername }}
                </RouterLink>
            </div>
            <UseTimeAgo v-if="props.createdAt" class="commentTime" v-slot="{ timeAgo }" :time="props.createdAt">
                {{ timeAgo }}
            </UseTimeAgo>
        </div>
        <span
            :class="props.hasReplies ? 'commentText hasReply' : 'commentText'"
            v-linkify:options="{
                target: '_blank',
                attributes: {
                    onclick: 'event.stopPropagation()',
                },
                className: 'link',
            }"
            >{{ props.text }}</span
        >
        <div :class="props.hasReplies ? 'interactions hasReply' : 'interactions'">
            <InteractButton
                :label="$t('interactions.like')"
                :state="liked"
                :count="likes"
                :icon="PhHeart"
                :action="performLike"
            />
            <InteractButton
                v-if="!props.reply"
                :label="$t('interactions.reply')"
                :state="false"
                :count="replies"
                :icon="PhChatCircleText"
                :action="showCompose"
            />
        </div>
    </div>
    <Transition name="comment-box">
        <CommentCompose v-if="compose" @new="addReply" parentType="comment" :parentId="props.id!" />
    </Transition>
</template>

<style lang="scss" scoped>
.comment-box-enter-active,
.comment-box-leave-active {
    transition:
        opacity 0.2s,
        transform 0.2s;
    transform: translateY(0);
}

.comment-box-enter-from,
.comment-box-leave-to {
    transform: translateY(30px);
    opacity: 0;
}

.comment {
    margin-top: 1em;
}

.reply {
    padding-left: 2em;
    margin-left: 1em;
    margin-top: 0;
    position: relative;
    border-left: 2px solid var(--mint-900);

    &.last {
        border-left: 2px solid transparent;
    }

    &::before {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        border-bottom-left-radius: 100%;
        border: 2px solid var(--mint-900);
        border-top: 0;
        border-right: 0;
        position: absolute;
        left: calc(2px * -1);
        top: 0;
    }
}

.topLine {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1em;
    z-index: 1;
}

.profileLine {
    display: flex;
    flex-direction: row;
}

.commentTime {
    color: var(--darkGray-300);
    margin-left: 0.25em;
}

b {
    font-weight: 700;
    display: flex;
    align-items: center;
}

.pfp {
    margin-right: 0.5em;
}

.username {
    display: flex;
    align-items: center;
    gap: 0.5em;
    color: var(--fg);
    background: var(--mint-900);
    padding: 0.1em 0.5em;
    border-radius: 99px;
    width: min-content;

    &.usernameMargin {
        margin: 0 0.5em;
    }
}

.commentText,
.interactions {
    padding: 0 0 0.75em 2em;
    margin-left: 1em;
    display: block;

    &.hasReply {
        position: relative;
        border-left: 2px solid var(--mint-900);
    }
}

.interaction {
    padding: 0.5em 0.75em;
    display: inline-flex;
    align-items: center;
    margin-right: 0.5em;
    font-size: 1em;
    color: var(--fg);
    border-radius: 10px;
    background-color: var(--darkGray-500);
    font-family: var(--fonts);
    border: none;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;

    svg {
        margin-right: 0.5em;
        transform: scale(1.2);
    }

    &:hover {
        background-color: var(--darkGray-400);
    }

    &.enabled {
        background-color: var(--mint-900);
    }
}
</style>
