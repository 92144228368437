<script setup lang="ts">
import { trackUmamiEvent } from "@jaseeey/vue-umami-plugin";
import type Button from "primevue/button";
import type Textarea from "primevue/textarea";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { api } from "~/inertia/api";
import { normalizeComment } from "~/utils/comment";

const toast = useToast();
const i18n = useI18n();

const props = defineProps<{
	parentId: string;
	parentType: "market" | "comment";
}>();

const emit = defineEmits(["new"]);

const composeBox = ref<typeof Textarea>();
const submitButton = ref<typeof Button>();
const composeText = ref<string>();
const composeDisabled = ref<boolean>();

async function submitComment() {
	if (!composeText.value || composeText.value.length === 0) {
		toast.add({
			summary: i18n.t("comments.noContent"),
			severity: "error",
			life: 3000,
		});
		return;
	}
	if (composeText.value.length >= 2000) {
		toast.add({
			summary: i18n.t("comments.tooLong"),
			severity: "error",
			life: 3000,
		});
		return;
	}
	composeDisabled.value = true;
	const result = await api.comment.post({
		id: props.parentId.toString(),
		target: props.parentType,
		content: composeText.value,
	});
	if (result.error) {
		console.error(result.error);
		composeDisabled.value = false;
		toast.add({
			summary: i18n.t("comments.error", { error: result.error.value }),
			severity: "error",
		});
		return;
	}
	const comment = await normalizeComment(result.data);
	trackUmamiEvent("comment-create", { commentId: comment.id });
	emit("new", {
		comment,
		parentId: props.parentId,
		parentType: props.parentType,
	});
	toast.add({
		summary: i18n.t("comments.posted"),
		life: 3000,
	});
	composeText.value = "";
	composeDisabled.value = false;
}
</script>

<template>
    <div class="compose">
        <Textarea
            ref="composeBox"
            v-model="composeText"
            autoResize
            class="composeText"
            :placeholder="$t('comments.placeholder')"
            :disabled="composeDisabled"
        ></Textarea>
        <Toast />
        <Button
            ref="submitButton"
            @click="submitComment"
            :disabled="composeDisabled"
            style="margin-bottom: 1em"
            v-vibrate="10"
        >
            {{ $t("comments.publish") }}
        </Button>
    </div>
</template>

<style lang="scss" scoped>
.compose {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 1em;

    .composeText {
        width: 100%;
    }
}

.composeText {
    flex: 1;
}
</style>
