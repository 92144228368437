import { type Component, type Ref, markRaw, ref } from "vue";
import type { ComponentEmit, ComponentProps } from "vue-component-type-helpers";

const fxId = ref(0);
export const fx = ref([]) as Ref<
	{
		id: number;
		component: Component;
		props: Record<string, any>;
		events: Record<string, any>;
	}[]
>;

export function showFx<Comp extends Component>(
	component: Comp,
	props: ComponentProps<Comp>,
	events: ComponentEmit<Comp> = {} as ComponentEmit<Comp>,
): { dispose: () => void } {
	markRaw(component);

	const id = ++fxId.value;
	const dispose = () => {
		window.setTimeout(() => {
			fx.value = fx.value.filter((effect) => effect.id !== id);
		}, 0);
	};
	const state = {
		component,
		props,
		events,
		id,
	};
	fx.value.push(state);

	return {
		dispose,
	};
}
