import type { Likes } from "@/database/entities/likes";
import type { RecordId } from "surrealdb";

export type User = {
	id: RecordId<string>;
	name: string;
	display_name?: string;
	created_at: string;
	pfp?: string;
	role?: string;
	is_pro: boolean;
};

export type RawComment = {
	id: RecordId<string>;
	author: RecordId<"user">;
	content: string;
	on: string;
	comments: RawComment[];
	created_at: string;
	updated_at: string;
	likes?: Likes[];
};

export type Comment = {
	id: RecordId<string>;
	author?: User;
	content: string;
	comments?: Comment[];
	created_at: string;
	updated_at: string;
	liked: boolean;
	likes?: Likes[];
	replies: number;
};

export type Trade = {
	id: RecordId<string>;
	market_id?: RecordId<"ponder_market">;
	timestamp: Date;
	quantity: number; // bigints can't be transferred as-is via json
	price: number;
	buy_order: {
		outcome: boolean;
		trader: string;
		traderUser: User;
	};
	sell_order: {
		outcome: boolean;
		trader: string;
		traderUser: User;
	};
};

export type OrderBookData = {
	last: number;
	spread: number;
	asks: {
		price: number;
		quantity: number;
		percentage: number;
		orderCount: number;
	}[];
	bids: {
		price: number;
		quantity: number;
		percentage: number;
		orderCount: number;
	}[];
};

export enum ChartIntervals {
	Hour = "1H",
	Day = "1D",
	Week = "1W",
	Month = "1M",
	All = "All",
}
