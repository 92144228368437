<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import { api } from "~/inertia/api";
import { useAuthenticationStore } from "~/stores/authentication";

const props = defineProps({
	isMe: Boolean,
	username: String,
	id: String,
	showPopover: Boolean,
	size: String,
	imgSrc: String,
});

const authenticationStore = useAuthenticationStore();
const router = useRouter();

const size =
	(props.size as "xlarge" | "large" | "normal" | "small") ?? "xlarge";
let skeletonSize = "4rem";
if (size === "large") skeletonSize = "3rem";
if (size === "normal") skeletonSize = "2rem";
if (size === "small") skeletonSize = "1rem";

const loading = ref(true);
const display_name = ref<string | undefined>();
const username = ref<string>();
const id = ref<string>();
const pfpSrc = ref<string | undefined>();

async function getUserInfo() {
	id.value = props.id;
	if (props.imgSrc) {
		loading.value = false;
		pfpSrc.value = props.imgSrc;
		return;
	}
	if (props.isMe) {
		if (!authenticationStore.user?.id.id) {
			loading.value = false;
			return;
		}
		loading.value = false;
		id.value = authenticationStore.user.id.id as string;

		authenticationStore.$subscribe((_mutation, state) => {
			if (!state.user?.id) {
				return;
			}
			api.user({ id: state.user.id.id as string })
				.get()
				.then((infoReq) => {
					if (!infoReq.data) {
						return;
					}
					loading.value = false;
					pfpSrc.value =
						infoReq.data.profile_picture ??
						`https://api.dicebear.com/9.x/thumbs/png?seed=${username.value}`;
				});
		});
	}
	if (!id.value && props.username) {
		const userIdReq = await api.user.id({ name: props.username }).get();
		if (!userIdReq.data) {
			return;
		}
		id.value = userIdReq.data.id.id as string;
	}
	const infoReq = await api.user({ id: id.value as string }).get();
	if (!infoReq.data) {
		return;
	}

	username.value = infoReq.data.name;
	display_name.value = infoReq.data.display_name;
	pfpSrc.value =
		infoReq.data.profile_picture ??
		`https://api.dicebear.com/9.x/thumbs/png?seed=${username.value}`;
	loading.value = false;
}

getUserInfo();
</script>

<template>
    <Skeleton v-if="loading" shape="circle" class="profile-icon" :size="skeletonSize"></Skeleton>
    <Avatar
        v-else
        :size="size"
        shape="circle"
        class="profile-icon"
        @click="router.push(`/@${username}`)"
        :image="pfpSrc"
    />
</template>

<style scoped>
.profile-icon {
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 0 20px var(--darkGray-400);
    }
}
</style>
