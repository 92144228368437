<script setup lang="ts">
import { onMounted, ref } from "vue";
import { api } from "~/inertia/api";
import type { OrderBookData } from "~/utils/types";

const props = defineProps<{
	id: string;
	outcome: boolean;
}>();

const data = ref<OrderBookData>();
const empty = ref<boolean>(false);

onMounted(async () => {
	const bookReq = await api
		.market({
			id: props.id,
		})
		.orderbook.get({
			query: {
				outcome: props.outcome,
			},
		});
	if (bookReq.error) {
		empty.value = true;
		return;
	}
	data.value = bookReq.data;
	empty.value = false;
});
</script>

<template>
    <div :class="{ orderBook: true, sell: !props.outcome }">
        <table v-if="!empty">
            <thead>
                <tr>
                    <th>
                        {{ $t("orderBook.trade", { outcome: outcome ? $t("market.yes") : $t("market.no") }) }}
                    </th>
                    <th>{{ $t("orderBook.price") }}</th>
                    <th>{{ $t("orderBook.quantity") }}</th>
                    <th>{{ $t("orderBook.total") }}</th>
                </tr>
            </thead>
            <tbody style="align-self: center">
                <tr v-for="(ask, index) in data?.asks">
                    <td
                        class="bar"
                        :style="`background: linear-gradient(to right, var(--cinnamon-700) 0 ${ask.percentage}%, transparent ${ask.percentage}% 100%)`"
                    >
                        {{ data?.asks[index + 1] ? "" : "Asks" }}
                    </td>
                    <td>{{ ask.price }}&#162;</td>
                    <td>{{ ask.quantity }}</td>
                    <td>${{ (ask.price * 0.01 * ask.quantity).toFixed(2) }}</td>
                </tr>
                <tr class="info">
                    <td></td>
                    <td></td>
                    <td>
                        <b>Last: {{ data?.last }}&#162;</b>
                    </td>
                    <td>
                        <b v-if="data?.spread">Spread: {{ data?.spread }}&#162;</b>
                    </td>
                </tr>
                <tr v-for="(bid, index) in data?.bids">
                    <td
                        class="bar"
                        :style="`background: linear-gradient(to right, var(--mint-700) 0 ${bid.percentage}%, transparent ${bid.percentage}% 100%)`"
                    >
                        {{ index === 0 ? "Bids" : "" }}
                    </td>
                    <td>{{ bid.price }}&#162;</td>
                    <td>{{ bid.quantity }}</td>
                    <td>${{ (bid.price * 0.01 * bid.quantity).toFixed(2) }}</td>
                </tr>
            </tbody>
        </table>
        <div v-else>
            <p>{{ $t("empty") }}</p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.orderBook {
    --obBg: var(--mint-950);
    --obFg: var(--mint-100);
    --obBorder: var(--mint-700);

    width: 100%;
    min-height: 15rem;
    background: var(--obBg);
    border: 1px solid var(--obBorder);
    border-radius: 20px;
    padding: 1em;
    display: flex;
    flex-direction: column;

    &.sell {
        --obBg: var(--cinnamon-950);
        --obFg: var(--cinnamon-100);
        --obBorder: var(--cinnamon-700);
    }
}

.line {
    display: flex;
    flex-direction: row;
}

.bar {
    width: 10em;
}

td {
    padding: 0.75rem 1rem;
}

th {
    text-align: left;
    padding: 0.75rem;
    color: var(--obFg);
}

.info {
    border-width: 1px 0px;
    border-style: solid;
    border-color: var(--obBorder);
    color: var(--obFg);
}

table {
    border-collapse: collapse;
}
</style>
