import type { User } from "@/database/entities/user";
import type { ElysiaApp } from "@/index";
import {
	CubeSignerClient,
	type EnvInterface,
	type IdentityProof,
	envs,
} from "@cubist-labs/cubesigner-sdk";
import { treaty } from "@elysiajs/eden";
import HyperDX from "@hyperdx/browser";
import { jwtDecode } from "jwt-decode";
import { defineStore } from "pinia";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useCubeSignerStore } from "./cube-signer-store";
import { useOidcStore } from "./oidc";
import { useWalletStore } from "./wallet";

export enum LoginResult {
	Failure = "failure",
	Success = "success",
	NeedsSignup = "needs_signup",
}

const unauthenticatedClient = treaty<ElysiaApp>(
	import.meta.env.VITE_BACKEND_URL,
);

if (!import.meta.env.VITE_CUBIST_ENVIRONMENT) {
	throw new Error(
		"CUBIST_ENVIRONMENT environment variable is not defined, should be one of 'prod', 'beta', or 'gamma'",
	);
}

export class UsernameTakenError extends Error {
	constructor(message?: string) {
		super(message);
		this.name = "UsernameTakenError";
		Object.setPrototypeOf(this, new.target.prototype);
	}
}

export class InvalidIdentityProofError extends Error {
	constructor(message?: string) {
		super(message);
		this.name = "InvalidIdentityProofError";
		Object.setPrototypeOf(this, new.target.prototype);
	}
}

export class UserAlreadyExistsError extends Error {
	constructor(message?: string) {
		super(message);
		this.name = "UserAlreadyExistsError";
		Object.setPrototypeOf(this, new.target.prototype);
	}
}

if (!import.meta.env.VITE_CUBIST_ORGANIZATION_ID) {
	throw new Error("CUBIST_ORG environment variable is not defined");
}

async function getIdentityProof(
	idToken: string,
	environment: EnvInterface,
): Promise<IdentityProof> {
	return CubeSignerClient.proveOidcIdentity(
		environment,
		import.meta.env.VITE_CUBIST_ORGANIZATION_ID,
		idToken,
	);
}

function tokenHasExpired(token: string): boolean {
	const decodedToken = jwtDecode(token);
	const currentTime = Date.now().valueOf() / 1000;
	return decodedToken.exp ? decodedToken.exp < currentTime : true;
}

const CUBIST_ENVIRONMENT =
	{
		prod: envs.prod,
		beta: envs.beta,
		gamma: envs.gamma,
	}[import.meta.env.VITE_CUBIST_ENVIRONMENT as string] ?? envs.gamma;

export const useAuthenticationStore = defineStore(
	"authentication",
	() => {
		const user = ref<User | undefined>(undefined);
		const accessToken = ref<string | undefined>(undefined);
		const refreshToken = ref<string | undefined>(undefined);

		const oidcStore = useOidcStore();
		const router = useRouter();

		let tokenPromise: Promise<string | undefined> | null = null;

		async function login(id_token: string) {
			const identityProof = await getIdentityProof(
				id_token,
				CUBIST_ENVIRONMENT,
			);
			const signInResult = await unauthenticatedClient.auth[
				"sign-in"
			].post({
				identityProof: btoa(JSON.stringify(identityProof)),
			});

			if (signInResult.status === 200) {
				if (!signInResult.data?.user) {
					throw new Error("No user returned from sign-in");
				}

				user.value = signInResult.data?.user;
				accessToken.value = signInResult.data?.accessToken;
				refreshToken.value = signInResult.data?.refreshToken;

				setHyperDXUser(user.value);

				return LoginResult.Success;
			}

			if (signInResult.status === 404) {
				return LoginResult.NeedsSignup;
			}

			return LoginResult.Failure;
		}

		function setHyperDXUser(user: User) {
			HyperDX.setGlobalAttributes({
				userId: user.id?.id.toString() ?? "",
				userName: user.name,
				cubist_id: user.cubist_id,
				wallet_address: user.wallet_address,
			});
		}

		function logout() {
			user.value = undefined;
			accessToken.value = undefined;
			refreshToken.value = undefined;

			const oidcStore = useOidcStore();
			oidcStore.reset();

			const cubeSignerStore = useCubeSignerStore();
			cubeSignerStore.reset();

			const walletStore = useWalletStore();
			walletStore.reset();

			HyperDX.setGlobalAttributes({});

			router.push("/");
		}

		function isAuthenticated() {
			return user.value !== undefined;
		}

		// TODO: maybe this should just be checked in the signup component?
		function canSignUp() {
			return oidcStore.id_token !== undefined && !isAuthenticated();
		}

		async function signUp(username: string) {
			if (user.value) {
				throw new Error("Already signed in");
			}

			if (!oidcStore.id_token) {
				throw new Error("Cannot sign up without ID token");
			}

			const identityProof = await getIdentityProof(
				oidcStore.id_token,
				CUBIST_ENVIRONMENT,
			);

			const signUpResponse = await unauthenticatedClient.auth[
				"sign-up"
			].post({
				identityProof: btoa(JSON.stringify(identityProof)),
				userName: username,
			});

			switch (signUpResponse.status) {
				case 409:
					throw new UsernameTakenError();
				case 401:
					throw new InvalidIdentityProofError();
				case 422:
					throw new UserAlreadyExistsError();
			}

			if (!signUpResponse.data) {
				throw new Error("No data in sign up response");
			}

			user.value = signUpResponse.data?.user;
			accessToken.value = signUpResponse.data.accessToken;
			refreshToken.value = signUpResponse.data.refreshToken;

			setHyperDXUser(user.value);
		}

		async function getAccessToken(): Promise<string | undefined> {
			if (!tokenPromise) {
				tokenPromise = getAndPossiblyRefreshAccessToken();
			}
			return await tokenPromise.finally(() => {
				tokenPromise = null;
			});
		}

		async function getAndPossiblyRefreshAccessToken(): Promise<
			string | undefined
		> {
			if (!accessToken.value) {
				return;
			}

			if (!tokenHasExpired(accessToken.value)) {
				return accessToken.value;
			}

			if (refreshToken.value && !tokenHasExpired(refreshToken.value)) {
				try {
					await refreshTokens(refreshToken.value);
					return accessToken.value;
				} catch {
					logout();
				}
			}

			logout();
		}

		async function refreshTokens(token: string) {
			if (tokenHasExpired(token)) {
				throw new Error(
					"Refresh token has expired, can't refresh tokens",
				);
			}

			try {
				const response = await unauthenticatedClient.auth.refresh.post({
					refreshToken: token,
				});

				if (response.status === 200) {
					accessToken.value = response.data?.accessToken;
					refreshToken.value = response.data?.refreshToken;
					return;
				}
			} catch (e) {
				console.error(e);
			}

			throw new Error("Failed to refresh tokens");
		}

		return {
			user,
			accessToken,
			refreshToken,
			login,
			logout,
			isAuthenticated,
			canSignUp,
			signUp,
			getAccessToken,
		};
	},
	{
		persist: true,
	},
);
