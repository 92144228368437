<script setup lang="ts">
import type { UserMinimal } from "@/database/entities/user";
import { PhCaretLeft } from "@phosphor-icons/vue";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { api } from "~/inertia/api";

const route = useRoute();
const router = useRouter();

const followUsers = ref<UserMinimal[]>([]);
const userId = ref<string>("");

async function getFollows() {
	const userIdReq = await api.user
		.id({ name: route.params.username.toString() })
		.get();
	if (!userIdReq.data?.id) {
		router.push("/404");
		return;
	}
	userId.value = userIdReq.data.id.id as string;
	const follows = await api
		.user({ id: userId.value })
		[route.meta.followers ? "followers" : "following"].get();
	if (follows.data) {
		followUsers.value = follows.data ?? [];
	}
}

getFollows();
</script>

<template>
    <main>
        <h1 class="header">
            <RouterLink :to="'/@' + route.params.username">
                <PhCaretLeft weight="bold" />
            </RouterLink>
            {{ route.meta.followers ? $t("follow.followers") : $t("follow.following") }}
        </h1>
        <RouterLink
            :to="'/@' + user.name"
            :aria-label="user.display_name || user.name"
            v-if="followUsers.length > 0"
            v-for="user of followUsers"
        >
            <UserDetails :id="userId" />
        </RouterLink>
    </main>
</template>

<style lang="scss" scoped>
.header {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 0.5em;
}
</style>
