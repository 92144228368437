<script setup lang="ts">
import Fluid from "primevue/fluid";
import IftaLabel from "primevue/iftalabel";
import type { Address } from "viem";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useWalletStore } from "~/stores/wallet";
import { getWalletBalances, sendUSDCtoWallet } from "~/utils/onchainActions";

const walletStore = useWalletStore();
const i18n = useI18n();

const to = ref<string>("");
const amount = ref<number>(0);
const disabled = ref<boolean>(true);
const transferButtonText = ref<string>(i18n.t("transfer.transfer"));

async function doTransfer() {
	disabled.value = true;
	transferButtonText.value = i18n.t("transfer.transferring");
	await sendUSDCtoWallet(to.value as Address, amount.value * 1000000);
	transferButtonText.value = i18n.t("transfer.done");
}

watch([to, amount], () => {
	if (
		amount.value > 0 &&
		to.value?.startsWith("0x") &&
		to.value.length === 42
	) {
		disabled.value = false;
	} else {
		disabled.value = true;
	}
});

onMounted(async () => {
	if (!walletStore.walletAddress) {
		return;
	}
	const balances = await getWalletBalances(walletStore.walletAddress);
	amount.value = Number(balances.usdcBalance);
});
</script>

<template>
    <main>
        <p>{{ $t("transfer.description") }}</p>
        <p class="info">{{ $t("transfer.info") }}</p>
        <Fluid class="transfer">
            <IftaLabel>
                <InputNumber
                    id="amount"
                    v-model="amount"
                    inputId="currency-us"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    size="large"
                />
                <label for="amount">{{ $t("transfer.amount") }}</label>
            </IftaLabel>
            <IftaLabel>
                <InputText id="to" v-model="to" type="text" size="large" placeholder="0x..." />
                <label for="to">{{ $t("transfer.to") }}</label>
            </IftaLabel>
            <Button @click="doTransfer" :disabled="disabled">{{ transferButtonText }}</Button>
        </Fluid>
    </main>
</template>

<style lang="scss" scoped>
.transfer {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.info {
    font-size: 0.8em;
    color: var(--lightGray-600);
}
</style>
