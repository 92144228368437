import { CubeSignerClient } from "@cubist-labs/cubesigner-sdk";
import { Signer } from "@cubist-labs/cubesigner-sdk-ethers-v6";
import {
	type Address,
	encodeFunctionData,
	formatTransactionRequest,
	formatUnits,
	parseAbi,
	toHex,
} from "viem";
import { avalanche, avalancheFuji } from "viem/chains";
import { manager } from "~/cubist/browser-storage";
import { api } from "~/inertia/api";
import { useAuthenticationStore } from "~/stores/authentication";
import { publicAvaxClient, useWalletStore } from "~/stores/wallet";
import { DecentralizedMarketCoreAbi } from "../../../contracts/abis/DecentralizedMarketCore";
import { DecentralizedMarketFactoryAbi } from "../../../contracts/abis/DecentralizedMarketFactory";
import { TokenAbi } from "../../../contracts/abis/Token";

const usdcTokens = {
	43113: "0x5425890298aed601595a70ab815c96711a31bc65" as Address, // Avalanche Fuji
	43114: "0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e" as Address, // Avalanche C-Chain
};

const marketFactory = {
	43113: "0xeAF7687997d424817ebBA14E771396eFD7Fc7868" as Address, // Avalanche Fuji
	43114: "0xEd0A666725F9de47eB780CcA98646aBBB8F65BeA" as Address, // Avalanche C-Chain
};

const chain_id =
	import.meta.env.VITE_CUBIST_ENVIRONMENT === "prod"
		? avalanche.id
		: avalancheFuji.id;

async function getSigner() {
	const walletStore = useWalletStore();
	if (!walletStore.walletAddress) {
		throw new Error("No wallet address in auth store");
	}
	const client = await CubeSignerClient.create(manager);
	const signer = new Signer(walletStore.walletAddress, client);
	return signer;
}

export async function getWalletBalances(walletAddress: Address) {
	const erc20Abi = parseAbi([
		"function balanceOf(address account) view returns (uint256)",
	]);

	const usdcBalance = await publicAvaxClient.readContract({
		address: usdcTokens[chain_id],
		abi: erc20Abi,
		functionName: "balanceOf",
		args: [walletAddress],
	});

	return {
		usdcBalance: formatUnits(usdcBalance, 6),
	};
}

export async function getTokenBalances(market_address: Address) {
	const authenticationStore = useAuthenticationStore();
	if (!authenticationStore.user?.wallet_address) {
		throw new Error("No wallet address in auth store");
	}

	const yesBalance = await publicAvaxClient.readContract({
		address: market_address,
		abi: DecentralizedMarketCoreAbi,
		functionName: "balanceOf",
		args: [authenticationStore.user?.wallet_address as Address, BigInt(1)],
	});

	const noBalance = await publicAvaxClient.readContract({
		address: market_address,
		abi: DecentralizedMarketCoreAbi,
		functionName: "balanceOf",
		args: [authenticationStore.user?.wallet_address as Address, BigInt(0)],
	});

	return {
		yesBalance: formatUnits(yesBalance, 0),
		noBalance: formatUnits(noBalance, 0),
	};
}

export async function sendUSDCtoWallet(
	wallet_address: Address,
	quantity: number,
) {
	const walletStore = useWalletStore();
	if (!walletStore.walletAddress) {
		throw new Error("No wallet address in wallet store, cannot send USDC");
	}
	const calldata = await publicAvaxClient.prepareTransactionRequest({
		account: walletStore.walletAddress,
		to: usdcTokens[chain_id],
		value: BigInt(0),
		data: encodeFunctionData({
			abi: TokenAbi,
			functionName: "transfer",
			args: [wallet_address, BigInt(quantity)],
		}),
	});
	const txReq = formatTransactionRequest(calldata);
	if (!txReq.type) {
		throw new Error("No txReq type");
	}
	const sig = await walletStore.signTransaction({
		chain_id: chain_id,
		tx: {
			gas: txReq.gas,
			data: txReq.data,
			maxFeePerGas: txReq.maxFeePerGas,
			maxPriorityFeePerGas: txReq.maxPriorityFeePerGas,
			nonce: txReq.nonce,
			to: txReq.to as Address,
			type: txReq.type,
			value: txReq.value,
		},
	});
	const serializedTransaction = sig.data().rlp_signed_tx as Address;
	const txId = await publicAvaxClient.sendRawTransaction({
		serializedTransaction,
	});
	const transaction = await publicAvaxClient.waitForTransactionReceipt({
		hash: txId,
	});
	return txId;
}

async function approveToken(
	market_address: Address,
	quantity: number,
	price: number,
) {
	const walletStore = useWalletStore();
	const data = await publicAvaxClient.getStorageAt({
		address: market_address,
		slot: toHex(54), //storage slot for IERC20 address
	});

	const token = String(data).substring(26, 66);
	const token_address = `0x${token}` as Address;

	const calldata = await publicAvaxClient.prepareTransactionRequest({
		account: walletStore.walletAddress,
		to: token_address,
		value: BigInt(0),
		data: encodeFunctionData({
			abi: TokenAbi,
			functionName: "approve",
			args: [market_address, BigInt(quantity * price)],
		}),
	});
	const txReq = formatTransactionRequest(calldata);
	if (!txReq.type) {
		throw new Error("No txReq type");
	}
	const sig = await walletStore.signTransaction({
		chain_id: chain_id,
		tx: {
			gas: txReq.gas,
			data: txReq.data,
			maxFeePerGas: txReq.maxFeePerGas,
			maxPriorityFeePerGas: txReq.maxPriorityFeePerGas,
			nonce: txReq.nonce,
			to: txReq.to as Address,
			type: txReq.type,
			value: txReq.value,
		},
	});
	const serializedTransaction = sig.data().rlp_signed_tx as Address;
	const txId = await publicAvaxClient.sendRawTransaction({
		serializedTransaction,
	});
	const transaction = await publicAvaxClient.waitForTransactionReceipt({
		hash: txId,
	});
	return txId;
}

async function checkTokenAllowance(market_address: Address) {
	const walletStore = useWalletStore();
	if (!walletStore.walletAddress) {
		throw new Error(
			"No wallet address in wallet store, can't check allowance",
		);
	}

	const data = await publicAvaxClient.getStorageAt({
		address: market_address,
		slot: toHex(54), //storage slot for IERC20 address
	});

	const token = String(data).substring(26, 66);
	const token_address = `0x${token}` as Address;

	const allowanceAmt = await publicAvaxClient.readContract({
		address: token_address,
		abi: TokenAbi,
		functionName: "allowance",
		args: [walletStore.walletAddress, market_address],
	});

	return allowanceAmt;
}

async function checkFactoryAllowance() {
	const walletStore = useWalletStore();
	if (!walletStore.walletAddress) {
		throw new Error(
			"No wallet address in wallet store, can't check allowance",
		);
	}

	const allowanceAmt = await publicAvaxClient.readContract({
		address: usdcTokens[chain_id],
		abi: TokenAbi,
		functionName: "allowance",
		args: [walletStore.walletAddress, marketFactory[chain_id]],
	});

	return allowanceAmt;
}

async function approveMarket(market_address: Address, approved: boolean) {
	const walletStore = useWalletStore();
	const calldata = await publicAvaxClient.prepareTransactionRequest({
		account: walletStore.walletAddress,
		to: market_address,
		value: BigInt(0),
		data: encodeFunctionData({
			abi: DecentralizedMarketCoreAbi,
			functionName: "setApprovalForAll",
			args: [market_address, approved],
		}),
	});

	const txReq = formatTransactionRequest(calldata);
	if (!txReq.type) {
		throw new Error("No txReq type");
	}
	const sig = await walletStore.signTransaction({
		chain_id: chain_id,
		tx: {
			gas: txReq.gas,
			data: txReq.data,
			maxFeePerGas: txReq.maxFeePerGas,
			maxPriorityFeePerGas: txReq.maxPriorityFeePerGas,
			nonce: txReq.nonce,
			to: txReq.to as Address,
			type: txReq.type,
			value: txReq.value,
		},
	});
	const serializedTransaction = sig.data().rlp_signed_tx as Address;
	const txId = await publicAvaxClient.sendRawTransaction({
		serializedTransaction,
	});
	const transaction = await publicAvaxClient.waitForTransactionReceipt({
		hash: txId,
	});
	return txId;
}

async function checkMarketApproval(market_address: Address) {
	const walletStore = useWalletStore();
	if (!walletStore.walletAddress) {
		throw new Error(
			"No wallet address in wallet store, can't check market approval",
		);
	}

	const approvalStatus = await publicAvaxClient.readContract({
		address: market_address,
		abi: DecentralizedMarketCoreAbi,
		functionName: "isApprovedForAll",
		args: [walletStore.walletAddress, market_address],
	});

	return approvalStatus;
}

export async function placeOrder(
	market_address: Address,
	quantity: number,
	price: number,
	outcome: boolean,
	isBuy: boolean,
): Promise<string> {
	const walletStore = useWalletStore();
	if (!walletStore.walletAddress) {
		throw new Error("No wallet address in wallet store, can't place order");
	}
	if (isBuy) {
		if (
			BigInt(quantity * price) >
			(await checkTokenAllowance(market_address))
		) {
			const approveTokenId = await approveToken(
				market_address,
				quantity,
				price,
			);
		}
	} else {
		if (!(await checkMarketApproval(market_address))) {
			const approveMarketId = await approveMarket(market_address, true);
		}
	}
	const calldata = await publicAvaxClient.prepareTransactionRequest({
		account: walletStore.walletAddress,
		to: market_address,
		value: BigInt(0),
		data: encodeFunctionData({
			abi: DecentralizedMarketCoreAbi,
			functionName: "placeOrder",
			args: [
				walletStore.walletAddress,
				BigInt(quantity),
				BigInt(price),
				outcome,
				isBuy,
			],
		}),
	});
	const txReq = formatTransactionRequest(calldata);
	if (!txReq.type || !txReq.nonce) {
		throw new Error("No txReq type");
	}
	const sig = await walletStore.signTransaction({
		chain_id: chain_id,
		tx: {
			gas: txReq.gas,
			data: txReq.data,
			maxFeePerGas: txReq.maxFeePerGas,
			maxPriorityFeePerGas: txReq.maxPriorityFeePerGas,
			nonce: txReq.nonce,
			to: txReq.to as Address,
			type: txReq.type,
			value: txReq.value,
		},
	});
	const serializedTransaction = sig.data().rlp_signed_tx as Address;
	const txId = await publicAvaxClient.sendRawTransaction({
		serializedTransaction,
	});
	const transaction = await publicAvaxClient.waitForTransactionReceipt({
		hash: txId,
	});
	return txId;
}

export async function cancelOrder(market_address: Address, order_id: number) {
	const walletStore = useWalletStore();
	const calldata = await publicAvaxClient.prepareTransactionRequest({
		account: walletStore.walletAddress,
		to: market_address,
		value: BigInt(0),
		data: encodeFunctionData({
			abi: DecentralizedMarketCoreAbi,
			functionName: "cancelOrder",
			args: [BigInt(order_id)],
		}),
	});
	const txReq = formatTransactionRequest(calldata);
	if (!txReq.type) {
		throw new Error("No txReq type");
	}
	const sig = await walletStore.signTransaction({
		chain_id: chain_id,
		tx: {
			gas: txReq.gas,
			data: txReq.data,
			maxFeePerGas: txReq.maxFeePerGas,
			maxPriorityFeePerGas: txReq.maxPriorityFeePerGas,
			nonce: txReq.nonce,
			to: txReq.to as Address,
			type: txReq.type,
			value: txReq.value,
		},
	});
	const serializedTransaction = sig.data().rlp_signed_tx as Address;
	const txId = await publicAvaxClient.sendRawTransaction({
		serializedTransaction,
	});
	const transaction = await publicAvaxClient.waitForTransactionReceipt({
		hash: txId,
	});
	return txId;
}

export async function creatorResolve(
	market_address: Address,
	paused: boolean,
	resolved: boolean,
	winningOutcome: boolean,
) {
	const walletStore = useWalletStore();
	const calldata = await publicAvaxClient.prepareTransactionRequest({
		account: walletStore.walletAddress,
		to: market_address,
		value: BigInt(0),
		data: encodeFunctionData({
			abi: DecentralizedMarketCoreAbi,
			functionName: "emergencyResolve",
			args: [paused, resolved, winningOutcome],
		}),
	});
	const txReq = formatTransactionRequest(calldata);
	if (!txReq.type) {
		throw new Error("No txReq type");
	}
	const sig = await walletStore.signTransaction({
		chain_id: chain_id,
		tx: {
			gas: txReq.gas,
			data: txReq.data,
			maxFeePerGas: txReq.maxFeePerGas,
			maxPriorityFeePerGas: txReq.maxPriorityFeePerGas,
			nonce: txReq.nonce,
			to: txReq.to as Address,
			type: txReq.type,
			value: txReq.value,
		},
	});
	const serializedTransaction = sig.data().rlp_signed_tx as Address;
	const txId = await publicAvaxClient.sendRawTransaction({
		serializedTransaction,
	});
	const transaction = await publicAvaxClient.waitForTransactionReceipt({
		hash: txId,
	});
	return txId;
}

export async function resolveMarket(market_address: Address) {
	const walletStore = useWalletStore();
	const calldata = await publicAvaxClient.prepareTransactionRequest({
		account: walletStore.walletAddress,
		to: market_address,
		value: BigInt(0),
		data: encodeFunctionData({
			abi: DecentralizedMarketCoreAbi,
			functionName: "resolveMarket",
			args: [],
		}),
	});
	const txReq = formatTransactionRequest(calldata);
	if (!txReq.type) {
		throw new Error("No txReq type");
	}
	const sig = await walletStore.signTransaction({
		chain_id: chain_id,
		tx: {
			gas: txReq.gas,
			data: txReq.data,
			maxFeePerGas: txReq.maxFeePerGas,
			maxPriorityFeePerGas: txReq.maxPriorityFeePerGas,
			nonce: txReq.nonce,
			to: txReq.to as Address,
			type: txReq.type,
			value: txReq.value,
		},
	});
	const serializedTransaction = sig.data().rlp_signed_tx as Address;
	const txId = await publicAvaxClient.sendRawTransaction({
		serializedTransaction,
	});
	const transaction = await publicAvaxClient.waitForTransactionReceipt({
		hash: txId,
	});
	return txId;
}

export async function redeemConditionalTokens(
	market_address: Address,
	amount: number,
) {
	const walletStore = useWalletStore();
	if (!walletStore.walletAddress) {
		throw new Error(
			"No wallet address in wallet store, can't redeem conditional tokens",
		);
	}

	const calldata = await publicAvaxClient.prepareTransactionRequest({
		account: walletStore.walletAddress,
		to: market_address,
		value: BigInt(0),
		data: encodeFunctionData({
			abi: DecentralizedMarketCoreAbi,
			functionName: "redeemConditionalTokens",
			args: [walletStore.walletAddress, BigInt(amount)],
		}),
	});
	const txReq = formatTransactionRequest(calldata);
	if (!txReq.type) {
		throw new Error("No txReq type");
	}
	const sig = await walletStore.signTransaction({
		chain_id: chain_id,
		tx: {
			gas: txReq.gas,
			data: txReq.data,
			maxFeePerGas: txReq.maxFeePerGas,
			maxPriorityFeePerGas: txReq.maxPriorityFeePerGas,
			nonce: txReq.nonce,
			to: txReq.to as Address,
			type: txReq.type,
			value: txReq.value,
		},
	});
	const serializedTransaction = sig.data().rlp_signed_tx as Address;
	const txId = await publicAvaxClient.sendRawTransaction({
		serializedTransaction,
	});
	const transaction = await publicAvaxClient.waitForTransactionReceipt({
		hash: txId,
	});
	return txId;
}

async function permitToken(
	closes_at: number,
	amount: number,
	spender: Address,
) {
	const walletStore = useWalletStore();
	if (!walletStore.walletAddress) {
		throw new Error(
			"No wallet address in wallet store, can't permit token",
		);
	}

	const amountValue = BigInt(amount);
	const deadline = BigInt(Math.round(closes_at / 10));

	const nonceAbi = parseAbi([
		"function nonces(address owner) external view returns (uint)",
	]);

	const nonces = await publicAvaxClient.readContract({
		address: usdcTokens[chain_id],
		abi: nonceAbi,
		functionName: "nonces",
		args: [walletStore.walletAddress],
	});

	const domain = {
		name: "USD Coin",
		version: "2",
		chainId: chain_id,
		verifyingContract: usdcTokens[chain_id],
	};

	const types = {
		Permit: [
			{
				name: "owner",
				type: "address",
			},
			{
				name: "spender",
				type: "address",
			},
			{
				name: "value",
				type: "uint256",
			},
			{
				name: "nonce",
				type: "uint256",
			},
			{
				name: "deadline",
				type: "uint256",
			},
		],
	};

	const message = {
		owner: walletStore.walletAddress,
		spender: spender,
		value: amountValue,
		nonce: nonces,
		deadline: deadline,
	};

	const value = message;
	const signer = await getSigner();

	const signature = await signer.signTypedData(domain, types, value);

	//viem permit signing
	//const signature = await sponsorClient.signTypedData({
	//	domain,
	//	types,
	//	primaryType: 'Permit',
	//	message,
	//});
	//console.log(signature);

	if (signature.length !== 132) {
		throw new Error("Invalid signature for permit");
	}

	const r = String(signature).substring(0, 66) as Address;
	const s = `0x${String(signature).substring(66, 130)}` as Address;
	const v = `0x${String(signature).substring(130, 132)}` as Address;

	return { v, r, s };
}

export async function sponsorCreatePrediction(
	closes_at: number,
	question: string,
	rules: string,
	description: string,
	creator_fee?: number,
) {
	const walletStore = useWalletStore();
	if (!walletStore.walletAddress) {
		return;
	}

	let creatorFeeInt = 150;
	if (creator_fee && creator_fee >= 0 && creator_fee <= 500) {
		creatorFeeInt = Math.floor(creator_fee);
	}

	const factoryAddress = marketFactory[chain_id];
	if (BigInt(9990000) > (await checkFactoryAllowance())) {
		const approval_amount = 99900000; //currently set to pre-approve 10 market creations
		const { v, r, s } = await permitToken(
			closes_at,
			approval_amount,
			factoryAddress,
		);
		const sponsorPermitData = {
			wallet_address: walletStore.walletAddress,
			approve_address: factoryAddress,
			amount: approval_amount,
			deadline: Math.round(closes_at / 10),
			v: v,
			r: r,
			s: s,
		};
		const txId = await api.sponsor.permit.post(sponsorPermitData);
		if (txId.error) {
			throw Error;
		}
	}
	const sponsorMarketData = {
		wallet_address: walletStore.walletAddress,
		closes_at: closes_at,
		question: question,
		description: description,
		rules: rules,
		creator_fee: creatorFeeInt,
		factory_address: factoryAddress,
	};
	const marketId = await api.sponsor.market.post(sponsorMarketData);
	if (marketId.error) {
		throw Error;
	}
	return marketId;
}

export async function sponsorPlaceOrder(
	market_address: Address,
	quantity: number,
	price: number,
	outcome: boolean,
	isBuy: boolean,
): Promise<string> {
	const walletStore = useWalletStore();
	if (!walletStore.walletAddress) {
		return "";
	}

	if (isBuy) {
		if (
			BigInt(quantity * price) >
			(await checkTokenAllowance(market_address))
		) {
			const closes_at = new Date().getTime() + 86400000;
			const approval_amount = 1000 * 1000000; //1000 USDC
			const { v, r, s } = await permitToken(
				closes_at,
				approval_amount,
				market_address,
			);
			const sponsorPermitData = {
				wallet_address: walletStore.walletAddress,
				approve_address: market_address,
				amount: approval_amount,
				deadline: Math.round(closes_at / 10),
				v: v,
				r: r,
				s: s,
			};
			const txId = await api.sponsor.permit.post(sponsorPermitData);
			if (txId.error) {
				throw Error;
			}
		}
	}
	const market_id = await getMarketID(market_address);
	const sponsorOrderData = {
		wallet_address: walletStore.walletAddress,
		market_id: Number(market_id),
		quantity: quantity,
		price: price,
		outcome: outcome,
		is_buy: isBuy,
	};
	const txId = await api.sponsor.order.post(sponsorOrderData);
	if (txId.error) {
		throw Error;
	}
	return `0x${txId.data}`;
}

export async function sponsorCancelOrder(
	market_address: Address,
	order_id: number,
) {
	const market_id = await getMarketID(market_address);
	const sponsorCancelData = {
		market_id: Number(market_id),
		order_id: order_id,
	};
	const txId = await api.sponsor.cancel.post(sponsorCancelData);
	if (txId.error) {
		throw Error;
	}
	return `0x${txId.data}`;
}

async function getMarketID(market_address: Address) {
	const marketId = await publicAvaxClient.readContract({
		address: marketFactory[chain_id],
		abi: DecentralizedMarketFactoryAbi,
		functionName: "getMarketId",
		args: [market_address],
	});

	return marketId;
}

export async function sponsorRedeemTokens(
	market_address: Address,
	amount: number,
) {
	const walletStore = useWalletStore();
	if (!walletStore.walletAddress) {
		throw new Error(
			"No wallet address in wallet store, can't redeem conditional tokens",
		);
	}
	const market_id = await getMarketID(market_address);
	const sponsorRedeemData = {
		market_id: Number(market_id),
		wallet_address: walletStore.walletAddress,
		amount: amount,
	};
	const txId = await api.sponsor.redeem.post(sponsorRedeemData);
	if (txId.error) {
		throw Error;
	}
	return `0x${txId.data}`;
}
