if (!import.meta.env.VITE_OIDC_TWITTER_CLIENT_ID) {
	throw new Error(
		"OIDC_TWITTER_CLIENT_ID environment variable is not defined",
	);
}

if (!import.meta.env.VITE_OIDC_DISCORD_CLIENT_ID) {
	throw new Error(
		"OIDC_DISCORD_CLIENT_ID environment variable is not defined",
	);
}

export enum OidcProvider {
	Twitter = "twitter",
	Discord = "discord",
	Google = "google",
}

interface TokenResponse {
	token_type?: string;
	access_token?: string;
	expores_in?: number;
	refresh_token?: string;
	scope?: string;
	id_token?: string;
}

export async function s256(code_verifier: string) {
	const encoder = new TextEncoder();
	const data = encoder.encode(code_verifier);
	const hashBuffer = await crypto.subtle.digest("SHA-256", data);

	return btoa(String.fromCharCode(...new Uint8Array(hashBuffer)))
		.replace(/\+/g, "-")
		.replace(/\//g, "_")
		.replace(/=+$/, "");
}

interface OidcFlow {
	start(csrfToken: string, pkce?: string): Promise<void>;
	callback(url: string, csrfToken?: string, pkce?: string): Promise<string>;
}

abstract class AbstractOidcFlow implements OidcFlow {
	abstract start(csrfToken: string, pkce?: string): Promise<void>;
	abstract callback(
		url: string,
		csrfToken?: string,
		pkce?: string,
	): Promise<string>;
}

interface AuthorizationCodeFlowConfiguration {
	authorize_url: string;
	token_url: string;
	client_id: string;
	scopes: string[];
}

class AuthorizationCodeFlowPKCE extends AbstractOidcFlow {
	constructor(private readonly config: AuthorizationCodeFlowConfiguration) {
		super();
	}

	async start(csrfToken?: string, pkce?: string): Promise<void> {
		console.debug("Starting authorization code flow...");
		const url = new URL(this.config.authorize_url);
		url.searchParams.append("response_type", "code");
		url.searchParams.append("client_id", this.config.client_id);
		url.searchParams.append("scope", this.config.scopes.join(" "));
		if (csrfToken) {
			url.searchParams.append("state", csrfToken);
		}
		if (pkce) {
			url.searchParams.append("code_challenge", await s256(pkce));
			url.searchParams.append("code_challenge_method", "S256");
		}
		url.searchParams.append(
			"redirect_uri",
			`${import.meta.env.VITE_FRONTEND_URL}/callback`,
		);

		window.location.href = url.toString();
	}
	async callback(
		url: string,
		csrfToken?: string,
		pkce?: string,
	): Promise<string> {
		const urlObj = new URL(url);

		if (urlObj.searchParams.get("state") !== csrfToken) {
			throw new Error("Invalid CSRF token");
		}

		const code = urlObj.searchParams.get("code");

		if (!code) {
			throw new Error("No code in URL");
		}

		const tokenResponse: TokenResponse = await (
			await fetch(this.config.token_url, {
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
				body: new URLSearchParams({
					grant_type: "authorization_code",
					client_id: this.config.client_id,
					redirect_uri: `${import.meta.env.VITE_FRONTEND_URL}/callback`,
					code: code,
					...(pkce && { code_verifier: pkce }),
				}),
			})
		).json();

		if (!tokenResponse.id_token) {
			throw new Error("Token response does not contain an ID token!");
		}

		return tokenResponse.id_token;
	}
}

// class ImplicitFlow extends AbstractOidcFlow {
// 	constructor(private readonly config: ImplicitFlowConfiguration) {
// 		super();
// 	}

// 	start(csrfToken: string): void {
// 		console.debug("Starting implicit flow...");

// 		const url = new URL(this.config.authorize_url);
// 		url.searchParams.append("response_type", "id_token");
// 		url.searchParams.append("client_id", this.config.client_id);
// 		url.searchParams.append("state", csrfToken);
// 		url.searchParams.append("scope", this.config.scopes.join(" "));

// 		console.debug("Redirecting to", url.toString());

// 		window.location.href = url.toString();
// 	}

// 	callback(redirect_url: string, csrfToken: string): string {
// 		console.debug("Handling callback for implicit flow...");
// 		console.debug("Redirect URL:", redirect_url);
// 		const url = new URL(redirect_url);

// 		//access_token, token_type, expires_in, scope, and state

// 		//const id_token = url.searchParams.get("id_token");
// 		//const

// 		return "id_token: foobar";
// 	}
// }

// interface ImplicitFlowConfiguration {
// 	scopes: string[];
// 	client_id: string;
// 	authorize_url: string;
// }

// https://discord.com/oauth2/authorize?response_type=token&client_id=1241808261527638148&state=foobar&scope=openid
// goes to: http://localhost:5173/callback#token_type=Bearer&access_token=dIFIC265ViYSeWQzfIRn9OkSNMiZMR&expires_in=604800&scope=guilds.join+openid+identify+email&state=foobar
//

export function getFlow(provider: OidcProvider): OidcFlow {
	switch (provider) {
		case OidcProvider.Discord:
			return new AuthorizationCodeFlowPKCE({
				authorize_url: "https://discord.com/api/oauth2/authorize",
				token_url: "https://discord.com/api/oauth2/token",
				client_id: import.meta.env.VITE_OIDC_DISCORD_CLIENT_ID,
				scopes: ["openid"],
			});
		case OidcProvider.Twitter:
			return new AuthorizationCodeFlowPKCE({
				authorize_url: "https://twitter.com/i/oauth2/authorize",
				token_url: `https://${import.meta.env.VITE_CUBIST_ENVIRONMENT}.signer.cubist.dev/v0/org/${encodeURIComponent(import.meta.env.VITE_CUBIST_ORGANIZATION_ID)}/oauth2/twitter`,
				client_id: import.meta.env.VITE_OIDC_TWITTER_CLIENT_ID,
				scopes: [
					"tweet.read",
					"users.read",
					"follows.read",
					"offline.access",
				],
			});
		default:
			throw new Error("Provider not yet implemented");
	}
}
