import type { Directive } from "vue";

export function vibrate(pattern: VibratePattern) {
	if (!window.navigator.vibrate) return;
	window.navigator.vibrate(pattern);
}

export default {
	mounted(el, binding) {
		const pattern = (binding.value as VibratePattern) ?? 20;
		el.addEventListener("mousedown", () => {
			vibrate(pattern);
		});
	},
} as Directive;
