<script setup lang="ts">
import "@fontsource/ibm-plex-sans";
import HyperDX from "@hyperdx/browser";
import DynamicDialog from "primevue/dynamicdialog";
import { onMounted } from "vue";
import { initializeDirective } from "~/directives/auth";
import { useViewportStore } from "~/stores/viewport";
import { fx } from "~/utils/popup";
import Footer from "./layout/Footer.vue";
import Header from "./layout/Header.vue";
import MobileNavigation from "./layout/MobileNavigation.vue";

const viewport = useViewportStore();

onMounted(() => {
	const element = document.querySelector("html") as HTMLHtmlElement;
	element.classList.toggle("p-dark");
	initializeDirective();

	if (
		import.meta.env.VITE_HYPERDX_API_KEY &&
		import.meta.env.VITE_HYPERDX_SERVICE_FRONTEND
	) {
		HyperDX.init({
			apiKey: import.meta.env.VITE_HYPERDX_API_KEY,
			service: import.meta.env.VITE_HYPERDX_SERVICE_FRONTEND,
			tracePropagationTargets: [
				import.meta.env.VITE_BACKEND_URL.replace(/^https?:\/\//, ""),
			], // Set to link traces from frontend to backend requests
			consoleCapture: true,
			recordCanvas: true,
		});
	}
});
</script>

<template>
    <Header></Header>
    <div class="wrapper">
        <main>
            <router-view v-slot="{ Component, route }">
                <transition name="route" mode="out-in" appear>
                    <component :is="Component" :key="route.fullPath" />
                </transition>
            </router-view>
        </main>
        <Footer></Footer>
        <MobileNavigation v-if="viewport.isMobile.value" />
    </div>
    <DynamicDialog />
    <component :is="i.component" v-for="i in fx" :key="i.id" v-bind="i.props" v-on="i.events" />
</template>

<style lang="scss">
#app {
    width: 100%;
    display: flex;
    flex-direction: row;
}

@media (min-width: 1050px) {
    #app {
        max-width: unset;
        margin: 0 5vw;
    }
}

@media (max-width: 600px) {
    #app {
        flex-direction: column;
    }
}

.route-enter-from {
    opacity: 0;
    transform: translateY(15px);
}

.route-enter-active,
.route-leave-active {
    transition:
        opacity 0.15s ease-out,
        transform 0.1s ease-out;
}

.route-leave-to {
    opacity: 0;
    transform: translateY(15px);
}
</style>

<style lang="scss" scoped>
div.wrapper {
    flex: 1;
    gap: 2em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2em;
}

@media (max-width: 600px) {
    div.wrapper {
        border: none;
        gap: unset;
        margin-bottom: 10em;
        gap: 1em;
    }
}
</style>
