<script setup lang="ts">
const props = defineProps<{
	outcome: boolean;
}>();
</script>

<template>
    <h2 class="shimmer" :class="props.outcome ? '' : 'shimmer-no'">
        {{ props.outcome ? $t("market.yes") : $t("market.no") }}
    </h2>
</template>

<style lang="scss" scoped>
.shimmer {
    --col: var(--mint-400);
    --shine: var(--mint-50);
    --shadow: var(--mint-700);
    background-color: var(--col);
    background-image: linear-gradient(
        -75deg,
        transparent 0,
        transparent 5%,
        var(--shine) 5%,
        var(--shine) 10%,
        transparent 5%,
        transparent 15%
    );
    background-size: 200% 100%;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    font-weight: bold;
    filter: drop-shadow(0 0 1em var(--shadow)) drop-shadow(0 0 2em var(--shadow));
    margin: 0;
    font-size: 2em;
    // animation: shimmer 2s infinite cubic-bezier(0.65, 0, 0.35, 1); // https://easings.net/#easeInOutCubic

    &.shimmer-no {
        --col: var(--cinnamon-500);
        --shine: var(--cinnamon-50);
        --shadow: var(--cinnamon-600);
    }
}

// @keyframes shimmer {
//     to {
//         background-position: -120% 0;
//     }
//     from {
//         background-position: 0 0;
//     }
// }
</style>
