<script setup lang="ts">
import { trackUmamiEvent } from "@jaseeey/vue-umami-plugin";
import { PhOpenAiLogo } from "@phosphor-icons/vue";
import DatePicker from "primevue/datepicker";
import Editor from "primevue/editor";
import "@vuepic/vue-datepicker/dist/main.css";
import Message from "primevue/message";
import ProgressSpinner from "primevue/progressspinner";
import Select from "primevue/select";
import Timeline from "primevue/timeline";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import SparkleButton from "~/components/SparkleButton.vue";
import { vibrate } from "~/directives/vibrate";
import { api } from "~/inertia/api";
import { useWalletStore } from "~/stores/wallet";
import { sponsorCreatePrediction } from "~/utils/onchainActions";

const router = useRouter();
const walletStore = useWalletStore();

const ready = ref(false);
const creatingMarket = ref(false);
// const creatorFee = ref(3);
const resolutionType = ref("Manual");
const question = ref("");
const rules = ref("");
const description = ref("");
const imageSrc = ref(null);
const enoughBalance = ref(false);
const closingDate = ref<Date>();
const resolutionTypes = ref([
	{ name: "Manual", resolutionType: "manual" },
	{ name: "REDACTED", resolutionType: "sneaky", disabled: true },
	{ name: "REDACTED", resolutionType: "not_yet", disabled: true },
	{ name: "REDACTED", resolutionType: "you_dirty_hacker", disabled: true },
]);
const events = ref<{ status: string; txId?: string }[]>([]);

function onFileSelect(event: any) {
	const file = event.files[0];
	const reader = new FileReader();

	reader.onload = async (e) => {
		// @ts-ignore
		imageSrc.value = e.target.result;
	};

	reader.readAsDataURL(file);
}

if (walletStore.usdcBalance) {
	enoughBalance.value = walletStore.usdcBalance >= 9.99;
}

watch(
	() => walletStore.usdcBalance,
	async (newValue, _) => {
		if (newValue && newValue >= 9.99) {
			enoughBalance.value = true;
		}
	},
);

watch([question, rules, description, closingDate], () => {
	if (
		question.value.length >= 10 &&
		question.value.length <= 100 &&
		rules.value.length >= 10 &&
		rules.value.length <= 1000 &&
		description.value.length >= 10 &&
		description.value.length <= 1000 &&
		closingDate.value &&
		new Date(closingDate.value).getTime() > new Date().getTime() &&
		new Date(closingDate.value).getTime() <
			new Date(new Date().getTime() + 31536000000).getTime()
	) {
		ready.value = true;
	} else {
		ready.value = false;
	}
});

const createMarket = async () => {
	if (!closingDate.value) return;
	creatingMarket.value = true;
	events.value.push({ status: "Creating prediction..." });
	trackUmamiEvent("create-prediction", {});
	const marketId = await sponsorCreatePrediction(
		closingDate.value.getTime() / 100,
		question.value,
		rules.value,
		description.value,
	);
	if (!marketId) {
		return;
	}
	events.value.push({
		status: "Prediction created! Sending you there now...",
	});
	setTimeout(() => {
		vibrate(50);
		router.push(`/p/${marketId.data.toString().replace("market:", "")}`);
	}, 250);
};
</script>

<template>
    <main>
        <div v-if="creatingMarket" class="cover">
            <h1>{{ $t("loading") }}</h1>
            <ProgressSpinner />
            <Message severity="warn">{{ $t("noReload") }}</Message>
            <Timeline style="max-height: 20rem" :value="events">
                <template #content="slotProps">
                    {{ slotProps.item.status }}
                    <span v-if="slotProps.item.txId">
                        <a :href="`https://testnet.snowtrace.io/tx/${slotProps.item.txId}`" target="_blank">
                            {{ slotProps.item.txId.substring(0, 5) + "..." + slotProps.item.txId.slice(-5) }}
                        </a>
                    </span>
                </template>
            </Timeline>
        </div>
        <div class="questions">
            <div class="question">
                <p>{{ $t("market.question") }}</p>
                <FloatLabel variant="on">
                    <InputText
                        id="question"
                        :allowEmpty="false"
                        v-model="question"
                        type="text"
                        size="large"
                        class="questionInput"
                        :maxLength="100"
                    />
                    <label for="question">{{ $t("market.questionPlaceholder") }}</label>
                </FloatLabel>
            </div>
            <div class="rules">
                <p class="title">{{ $t("market.rules") }}</p>
                <p class="description">
                    {{ $t("market.rulesDescription") }}
                    <br />
                    <a
                        class="link"
                        href="https://chatgpt.com/g/g-TxR3ihuGc-prediction-market-creator"
                        target="_blank"
                        style="display: flex; align-items: center; margin-top: 0.5em"
                    >
                        <PhOpenAiLogo style="margin-right: 0.5em" size="1.5em" /><span
                            >Use AI to help write your rules!</span
                        >
                    </a>
                </p>
                <Textarea
                    v-model="rules"
                    autoResize
                    rows="5"
                    cols="30"
                    id="rules"
                    :allowEmpty="false"
                    type="text"
                    size="large"
                    class="questionInput"
                    :placeholder="$t('market.rulesPlaceholder')"
                    :maxLength="1000"
                ></Textarea>
            </div>
            <div class="desciption">
                <p class="title">{{ $t("market.description") }}</p>
                <p class="description">{{ $t("market.descriptionDescription") }}</p>
                <Editor v-model="description" :formats="['bold', 'italic', 'underline', 'blockquote', 'list']">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                            <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                            <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                        </span>
                        <span class="ql-formats">
                            <!-- TODO: bullet list shows as ordered list -->
                            <button v-tooltip.bottom="'Ordered'" class="ql-list" value="ordered"></button>
                            <button v-tooltip.bottom="'Link'" class="ql-link"></button>
                            <button v-tooltip.bottom="'Blockquote'" class="ql-blockquote"></button>
                        </span>
                    </template>
                </Editor>
            </div>
            <div class="misc">
                <div class="closing">
                    <p class="title">{{ $t("market.closing.at") }}</p>
                    <p class="description">{{ $t("market.closingDescription") }}</p>
                    <FloatLabel variant="on">
                        <DatePicker
                            id="closingDate"
                            v-model="closingDate"
                            showTime
                            hourFormat="12"
                            :minDate="new Date(new Date().getTime() + 60)"
                            :maxDate="new Date(new Date().getTime() + 31536000000)"
                            showButtonBar
                            fluid
                        />
                        <label for="closingDate">{{ $t("market.closingPlaceholder") }}</label>
                    </FloatLabel>
                </div>
                <div class="resolution">
                    <p class="title">{{ $t("market.resolutionType") }}</p>
                    <p class="description">{{ $t("market.moreSoon") }}</p>
                    <Select
                        v-model="resolutionType"
                        :options="resolutionTypes"
                        optionLabel="name"
                        optionDisabled="disabled"
                        :placeholder="$t('market.resolutionFormats.manual')"
                        checkmark
                    ></Select>
                </div>
                <div class="upload">
                    <p class="title">{{ $t("market.image") }}</p>
                    <p class="description">{{ $t("comingSoon") }}</p>
                    <FileUpload
                        disabled
                        mode="basic"
                        @select="onFileSelect"
                        customUpload
                        auto
                        severity="secondary"
                        class="p-button-outlined"
                        :file-limit="1"
                        accept="image/*"
                        :maxFileSize="1024 * 1024 * 10"
                    />
                    <img v-if="imageSrc" :src="imageSrc" alt="Image" class="imagePreview" />
                </div>
            </div>
            <p
                v-html="
                    $t('market.youllEarn', {
                        amount: '<span style=\'font-size: 1em; color: var(--mint-400)\'>1.5%</span>',
                    })
                "
                class="youll-earn"
            ></p>
        </div>
        <SparkleButton
            :disabled="!ready || !enoughBalance"
            v-tooltip.top="enoughBalance ? '' : $t('insufficientBalance')"
            :text="$t('market.create')"
            :fn="createMarket"
        />
    </main>
</template>

<style lang="scss" scoped>
.questions {
    display: flex;
    flex-direction: column;
    gap: 1em;

    p {
        font-weight: bold;
        font-size: 1.2em;
        margin-bottom: 1.5em;

        &.title {
            margin-bottom: 0.5em;
        }

        &.description {
            font-size: 0.8em;
            color: var(--lightGray-500);
        }
    }

    .misc {
        display: flex;
        flex-direction: row;
        gap: 1em;

        @media (max-width: 768px) {
            flex-direction: column;
        }

        .closing {
            min-width: 12em;
        }

        .creatorFee {
            span {
                * {
                    max-width: 4em;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .questions {
        margin: 0 0 3rem 0;
    }
}

.questionInput {
    width: 100%;
    height: 3em;
    border-radius: 0.5rem;
    border: 2px solid var(--lightGray-700);
    background-color: var(--darkGray-700);
    color: var(--fg);
    padding: 0.5em;
    margin-top: 0.2em;

    &:active,
    &:focus {
        outline: 0;
        background-color: var(--darkGray-700);
    }
}

.imagePreview {
    margin-top: 1em;
    max-width: 10rem;
    border-radius: 20px;
}

.chips {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 0.5em;
    margin-top: 0.5em;

    .chip {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: solid 2px var(--lightGray-700);
        padding: 0.5em;
        border-radius: 99px;
        background-color: var(--darkGray-500);
        color: var(--fg);
        transition: all 0.15s ease-in;

        svg {
            margin-right: 0.1em;
            min-width: 24px;
        }

        &:hover {
            background-color: var(--darkGray-400);
        }

        &.selected {
            background-color: var(--goodItemBgA);
            font-weight: bold;
            padding: 0.5em 1em;
        }
    }
}

.youll-earn {
    align-self: center;
    margin: 1em 0 0.5em 0 !important;
    font-size: 0.9em !important;
    color: var(--mint-600);
}
</style>
